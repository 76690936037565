export const customStyles = {
  menuPortal: (provider) => ({
    ...provider,
    zIndex: 831, //@z-index-backdrop + 1;
  }),
  option: (provided, { isDisabled, isSelected }) => {
    return {
      ...provided,
      backgroundColor: isSelected && "#DBEEFE",
      fontSize: 14,
      color: "#49505A",
      fontFamily: "Lato",
      fontWeight: 600,
      paddingTop: 14,
      paddingLeft: 20,
      paddingRight: 20,
      minHeight: 45,
      cursor: "pointer",
      opacity: isDisabled ? 0.6 : 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",

      ":hover": {
        backgroundColor: "#EFF5FC",
      },
    };
  },
  control: (provided, { isDisabled, menuIsOpen, selectProps }) => {
    const { isError } = selectProps;

    return {
      ...provided,
      minHeight: 45,
      borderColor: isError ? "#f8b131" : menuIsOpen ? "#118AF9" : "#EBEFF4",
      borderWidth: isError ? 2 : 1,
      boxShadow: !isError && menuIsOpen && "0 0 0 1px #118AF9",
      borderRadius: 5,
      paddingLeft: 8,
      backgroundColor: isDisabled ? "#F8F9FC" : "#FFFFFF",
      opacity: isDisabled ? 0.6 : 1,
      cursor: "pointer",

      ":hover": {
        borderWidth: 1,
        borderColor: "#118AF9",
        boxShadow: "0 0 0 1px #118AF9",
      },
    };
  },
  dropdownIndicator: (provided, { selectProps }) => {
    const { menuIsOpen } = selectProps;
    const transform = menuIsOpen ? "rotate(-180deg)" : "rotate(0deg)";
    const color = menuIsOpen ? "#118AF9" : "#A5AAC7";

    return {
      ...provided,
      color: "#A5AAC7",
      marginRight: 7,

      svg: {
        height: 16,
        width: 16,
        transform,
        color,
        transition: "all 0.3s",
      },

      ":hover": {
        color: "#A5AAC7",
      },
    };
  },
  placeholder: (provider) => ({
    ...provider,
    fontSize: 14,
    lineHeight: 36,
    color: "#95A1B2",
    fontFamily: "Lato",
    fontWeight: 500,
  }),
  indicatorSeparator: () => ({
    border: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    lineHeight: 36,
    color: "#49505A",
    fontFamily: "Lato",
    fontWeight: 700,
  }),
  valueContainer: (provided, { isDisabled }) => ({
    ...provided,
    opacity: isDisabled ? 0.6 : 1,
  }),
  menuList: (provided) => ({
    ...provided,

    "::-webkit-scrollbar": {
      width: "10px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
      borderRadius: "4px",
      height: "calc(100% - 20px)",
      overflow: "hidden",
      position: "absolute",
      right: 0,
      top: 10,
      width: "10px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      cursor: "pointer",
      width: "10px",
      background: "#95A1B2",
    },
  }),
  menu: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    boxShadow: "2px 10px 50px #3F4B8021",
    zIndex: 200,
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "#DBEEFE 0% 0% no-repeat padding-box",
    border: "1px solid #118AF9",
    borderRadius: "50px",
    padding: "0 10px",
    height: "30px",
    display: "flex",
    margin: "5px",
    justifyContent: "center",
    alignItems: "center",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#118AF9",
    fontSize: "14px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,

    ":hover": {
      backgroundColor: "transparent",
    },

    svg: {
      height: 17,
      width: 17,
      color: "#118AF9",
      transition: "all 0.3s",

      ":hover": {
        color: "#0462B8",
      },
    },
  }),
};
