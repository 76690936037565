import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import {
  fetchVehicleErrors,
  clearDTC,
  selectVehicle,
  clearVehicleData,
} from "../../vehicles.slice";
import {
  getSelectedVehicle,
  isFaultDataLoading,
  getDtcFaultData,
  getIsClearDTCLoading,
} from "../../vehicles.selectors";
import { getMeasurementSystem } from "../../../customers/customers.selectors";

import Spinner from "../../../../components/spinner/Spinner";
import DtcCodeRow from "./DtcCodeRow";

import classes from "./DtcCodes.module.scss";

import { metricToImperialConvertMethods } from "../../../../utils";
import { EmptyDTCCodesIcon } from "../../../../assets/icons";

export default function DtcCodes({
  className,
  vehicleId,
  deviceId,
  showActive,
}) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState(new Set());

  const selectedVehicle = useSelector(getSelectedVehicle);
  const isLoading = useSelector(isFaultDataLoading);
  const faultData = useSelector(getDtcFaultData);
  const measurementSystem = useSelector(getMeasurementSystem);
  const isСlearDTCLoading = useSelector(getIsClearDTCLoading);
  const isDTCEmpty = !faultData || !faultData.faults.length;

  useEffect(() => {
    if (selectedVehicle && !vehicleId) {
      dispatch(fetchVehicleErrors(selectedVehicle));
    }
  }, [selectedVehicle && selectedVehicle.id && selectedVehicle.deviceId]);

  useEffect(() => {
    if (vehicleId && deviceId) {
      //rendering in InfoTooltip
      dispatch(fetchVehicleErrors({ id: vehicleId, deviceId }));
      dispatch(selectVehicle(vehicleId));
    }

    return () => {
      dispatch(clearVehicleData(vehicleId));
      dispatch(selectVehicle(null));
    };
  }, [vehicleId]);

  function expandRow(id) {
    if (!expandedRows.has(id)) {
      expandedRows.add(id);
      setExpandedRows(new Set(expandedRows));
    }
  }

  function collapseRow(id) {
    if (expandedRows.has(id)) {
      expandedRows.delete(id);
      setExpandedRows(new Set(expandedRows));
    }
  }

  if (isLoading) {
    return (
      <div
        className={cn([
          classes.SpinnerWrapper,
          className,
          "VehicleInfoPanel__headerLabel position-relative",
        ])}
      >
        <Spinner />
      </div>
    );
  }

  if (!selectedVehicle || !faultData) {
    return null;
  }

  const numOfActiveFaults = faultData?.faults.filter((dtc) =>
    isActiveState(dtc.occurences[0])
  ).length;
  const numOfPendingFaults = faultData?.faults.filter((dtc) =>
    isPendingState(dtc.occurences[0])
  ).length;
  const numOfInactiveFaults =
    faultData?.faults.length - numOfActiveFaults - numOfPendingFaults;

  function isActiveState(record) {
    return record.faultState && record.faultState.toLowerCase() === "active";
  }

  function isPendingState(record) {
    return record.faultState && record.faultState.toLowerCase() === "pending";
  }

  function handlerClearDTC() {
    dispatch(clearDTC(selectedVehicle));
  }

  return (
    <div className={cn(className, classes.DtcCodes, "d-flex flex-wrap")}>
      <div className="d-flex w-100 justify-content-between">
        <div className="VehicleInfoPanel__headerLabel">
          DTC Codes{" "}
          <span className={classes.DtcCodes__SecondaryLabel}>
            ({showActive ? numOfActiveFaults : faultData.faults.length})
          </span>
        </div>
        {!isDTCEmpty && !showActive && (
          <div className={cn("d-flex align-items-center", classes.counters)}>
            <div
              className={cn(
                classes.MobileDisplayNone,
                classes.DtcCodes__States
              )}
            >
              <div
                className={cn(
                  classes.DtcCodes__ActiveCounter,
                  "d-flex align-items-center"
                )}
              >
                Active:
                <span className={cn(classes.DtcCodes__CounterValue, "ml-1")}>
                  {numOfActiveFaults}
                </span>
              </div>
              <div
                className={cn(
                  classes.DtcCodes__InactiveCounter,
                  "d-flex align-items-center"
                )}
              >
                Inactive:
                <span className={cn(classes.DtcCodes__CounterValue, "ml-1")}>
                  {numOfInactiveFaults}
                </span>
              </div>
              <div
                className={cn(
                  classes.DtcCodes__PendingCounter,
                  "d-flex align-items-center"
                )}
              >
                Pending:
                <span className={cn(classes.DtcCodes__CounterValue, "ml-1")}>
                  {numOfPendingFaults}
                </span>
              </div>
            </div>
            {faultData.isClearDTCAvailable && !showActive && (
              <div>
                <button
                  className={classes.DtcCodes__ClearButton}
                  onClick={() => handlerClearDTC()}
                >
                  {isСlearDTCLoading ? (
                    <div
                      className={classes.DtcCodes__ClearButton__Loader}
                    ></div>
                  ) : (
                    "Clear DTC"
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {!isDTCEmpty ? (
        <div className={cn([classes.DtcCodesTable, "w-100"])}>
          <div className={classes.DtcCodesTable__Header}>
            <div className={classes.DtcCodesTable__HeaderCell}>
              Fault Description/Date
            </div>
            <div className={classes.DtcCodesTable__HeaderCell}>Code</div>
            <div className={classes.DtcCodesTable__HeaderCell}>
              Controller/Source
            </div>
            <div className={classes.DtcCodesTable__HeaderCell}>Engine Type</div>
            <div className={classes.DtcCodesTable__HeaderCell}>
              Freeze Frame
            </div>
            <div className={classes.DtcCodesTable__HeaderCell}>Fault State</div>
            <div className={classes.DtcCodesTable__HeaderCell}>Timestamps</div>
          </div>
          <div>
            {faultData.faults.map((faultRecord) => {
              if (showActive && !isActiveState(faultRecord.occurences[0])) {
                return null;
              }
              const isExpanded = expandedRows.has(faultRecord.diagnosticId);
              const isFreezeFrameAvailable = !!faultRecord.freezeFrame;
              const freezeFrame = { ...faultRecord.freezeFrame };

              if (isFreezeFrameAvailable && measurementSystem === "imperial") {
                for (const [key, val] of Object.entries(freezeFrame)) {
                  if (metricToImperialConvertMethods[val.unit]) {
                    freezeFrame[key] = { ...val };

                    const { value, unit } = metricToImperialConvertMethods[
                      val.unit
                    ](val.value);

                    freezeFrame[key].value = value;
                    freezeFrame[key].unit = unit;
                  }
                }
              }

              return isExpanded ? (
                <div className={classes.DtcCodesTable__ShadowRowWrapper}>
                  {faultRecord.occurences.map((timeEntry, i) => (
                    <DtcCodeRow
                      {...faultRecord}
                      key={faultRecord.diagnosticId + "_" + i}
                      isExpandable={i === 0}
                      onExpand={expandRow}
                      onCollapse={collapseRow}
                      time={timeEntry.time}
                      faultState={timeEntry.faultState}
                      isActive={isActiveState(timeEntry)}
                      isPending={isPendingState(timeEntry)}
                      isExpanded={true}
                      count={
                        i === 0 ? faultRecord.occurences.length : undefined
                      }
                      isFreezeFrameAvailable={isFreezeFrameAvailable}
                      freezeFrame={i === 0 && freezeFrame}
                      //! change names
                    />
                  ))}
                </div>
              ) : (
                <DtcCodeRow
                  {...faultRecord}
                  key={faultRecord.diagnosticId + "_0"}
                  isExpandable={faultRecord.occurences.length > 1}
                  onExpand={expandRow}
                  onCollapse={collapseRow}
                  time={faultRecord.occurences[0].time}
                  faultState={faultRecord.occurences[0].faultState}
                  isActive={isActiveState(faultRecord.occurences[0])}
                  isPending={isPendingState(faultRecord.occurences[0])}
                  isExpanded={false}
                  count={faultRecord.occurences.length}
                  isFreezeFrameAvailable={isFreezeFrameAvailable}
                  freezeFrame={freezeFrame}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className={classes.noData}>
          <EmptyDTCCodesIcon className={classes.icon} />
          <div className="font-md font-bold">There is no data to display.</div>
        </div>
      )}
    </div>
  );
}
