import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import cn from "classnames";
import {
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Alert,
} from "reactstrap";
import { formatPhoneWithoutCountry, joinShopAddress } from "../../../utils";
import { useScreenSize } from "../../../utility/hooks";

import styles from "./OrderDevicesModal.module.scss";
import classes from "../../../styles/modal.module.scss";
import formClasses from "../../../styles/input.module.scss";

import {
  CloseIcon,
  BuyDevicesItem1Icon,
  BuyDevicesItem2Icon,
  BuyDevicesItem3Icon,
  ArrowBackIcon,
  SuccessIcon,
  SalesIcon,
  DTCIcon,
  LoaderIcon,
  AttentionIconRed,
} from "../../../assets/icons";

import { getSelectedTenant } from "../../tenants/tenants.selectors";
import {
  nextStep,
  prevStep,
  orderDevices,
  clearOrderError,
} from "../../shops/shops.slice";
import {
  getShops,
  getSelectedShop,
  getCurrentStep,
  getIsOrderDevicesError,
  getIsOrderDevicesLoading,
} from "../../shops/shops.selectors";
import SelectInput from "../../../components/ui/select-input/SelectInput";
import PhoneInput from "../../../components/ui/phone-input/PhoneInput";

import { ORDER_DEVICES_STEP, ALL_LOCATIONS } from "../../../constants";
import { setShopIdHeader } from "../../../services/api.service";
import {
  getUserName,
  getUserFirstName,
  getUserLastName,
  getUserPhone,
} from "../../../App/ui.slice";
import countriesName from "../../../components/ui/phone-input/countriesName";

const STATES = {
  US: [
    { id: "AL", label: "Alabama" },
    { id: "AK", label: "Alaska" },
    { id: "AZ", label: "Arizona" },
    { id: "AR", label: "Arkansas" },
    { id: "CA", label: "California" },
    { id: "CO", label: "Colorado" },
    { id: "CT", label: "Connecticut" },
    { id: "DE", label: "Delaware" },
    { id: "DC", label: "District of Columbia" },
    { id: "FL", label: "Florida" },
    { id: "GA", label: "Georgia" },
    { id: "HI", label: "Hawaii" },
    { id: "ID", label: "Idaho" },
    { id: "IL", label: "Illinois" },
    { id: "IN", label: "Indiana" },
    { id: "IA", label: "Iowa" },
    { id: "KS", label: "Kansas" },
    { id: "KY", label: "Kentucky" },
    { id: "LA", label: "Louisiana" },
    { id: "ME", label: "Maine" },
    { id: "MD", label: "Maryland" },
    { id: "MA", label: "Massachusetts" },
    { id: "MI", label: "Michigan" },
    { id: "MN", label: "Minnesota" },
    { id: "MS", label: "Mississippi" },
    { id: "MO", label: "Missouri" },
    { id: "MT", label: "Montana" },
    { id: "NE", label: "Nebraska" },
    { id: "NV", label: "Nevada" },
    { id: "NH", label: "New Hampshire" },
    { id: "NJ", label: "New Jersey" },
    { id: "NM", label: "New Mexico" },
    { id: "NY", label: "New York" },
    { id: "NC", label: "North Carolina" },
    { id: "ND", label: "North Dakota" },
    { id: "OH", label: "Ohio" },
    { id: "OK", label: "Oklahoma" },
    { id: "OR", label: "Oregon" },
    { id: "PA", label: "Pennsylvania" },
    { id: "PR", label: "Puerto Rico" },
    { id: "RI", label: "Rhode Island" },
    { id: "SC", label: "South Carolina" },
    { id: "SD", label: "South Dakota" },
    { id: "TN", label: "Tennessee" },
    { id: "TX", label: "Texas" },
    { id: "UT", label: "Utah" },
    { id: "VT", label: "Vermont" },
    { id: "VA", label: "Virginia" },
    { id: "VI", label: "Virgin Islands" },
    { id: "WA", label: "Washington" },
    { id: "WV", label: "West Virginia" },
    { id: "WI", label: "Wisconsin" },
    { id: "WY", label: "Wyoming" },
  ],
  CA: [
    { id: "AB", label: "Alberta" },
    { id: "BC", label: "British Columbia" },
    { id: "MB", label: "Manitoba" },
    { id: "NB", label: "New Brunswick" },
    { id: "NL", label: "Newfoundland and Labrador" },
    { id: "NT", label: "Northwest Territories" },
    { id: "NS", label: "Nova Scotia" },
    { id: "NU", label: "Nunavut" },
    { id: "ON", label: "Ontario" },
    { id: "PE", label: "Prince Edward Island" },
    { id: "QC", label: "Quebec" },
    { id: "SK", label: "Saskatchewan" },
    { id: "YT", label: "Yukon" },
  ],
};

const COUNTRIES = Object.entries(countriesName).map(([key, value]) => ({
  value: key,
  label: value,
}));

const PRICING_DETAILS = [
  {
    icon: BuyDevicesItem1Icon,
    title: "Price Per Device",
    description: (
      <span>
        The price of the device is{" "}
        <span className={styles.highlighted}>$35</span>. One device is intended
        for one vehicle. Devices are purchased in multiples of 25.
      </span>
    ),
  },
  {
    icon: BuyDevicesItem2Icon,
    title: "Monthly Fee",
    description: (
      <span>
        Installed devices have a <span className={styles.highlighted}>$10</span>{" "}
        monthly fee. You do not pay until a device is installed.
      </span>
    ),
  },
  {
    icon: BuyDevicesItem3Icon,
    title: "Pay Later",
    description: (
      <span>
        You will not pay for this order now. We will contact you regarding
        payment after order confirmation.
      </span>
    ),
  },
];

export default function OrderDevicesModal({ onClose, isOpen }) {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const selectedTenant = useSelector(getSelectedTenant);
  const selectedShop = useSelector(getSelectedShop);
  const shops = useSelector(getShops);
  const [selectedShopId, setSelectedShopId] = useState(null);
  const isAllLocations = selectedShop.id === ALL_LOCATIONS.id;
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userPhone = useSelector(getUserPhone);
  const userEmail = useSelector(getUserName);
  const isOrderError = useSelector(getIsOrderDevicesError);
  const isOrderLoading = useSelector(getIsOrderDevicesLoading);
  const selectedShopDetails = isAllLocations
    ? shops.find((shop) => shop.id === selectedShopId) || {}
    : selectedShop || {};
  const { country, state, city, streetAddress, additionalStreet, zipCode } =
    selectedShopDetails.address || {};
  const shopCountry =
    COUNTRIES.find((option) => option.label === country) || null;
  const shopState =
    STATES[shopCountry?.value]?.find(
      (stateOption) => stateOption.label === state
    ) || null;
  const [selectedCountry, setSelectedCountry] = useState(shopCountry);
  const [selectedState, setSelectedState] = useState(shopState);
  const [selectedPhone, setSelectedPhone] = useState(userPhone || "");
  const [selectedCity, setSelectedCity] = useState(city || "");
  const [selectedStreetAddress, setSelectedStreetAddress] = useState(
    streetAddress || ""
  );
  const [selectedAdditionalStreet, setSelectedAdditionalStreet] = useState(
    additionalStreet || ""
  );
  const [selectedZipCode, setSelectedZipCode] = useState(zipCode || "");

  useEffect(() => {
    setSelectedCountry(shopCountry);
    setSelectedState(shopState);
    setSelectedCity(city || "");
    setSelectedStreetAddress(streetAddress || "");
    setSelectedAdditionalStreet(additionalStreet || "");
    setSelectedZipCode(zipCode || "");
  }, [selectedShopDetails]);

  const devicesQuantity = Array.from({ length: 200 / 25 }, (_, i) => ({
    label: ((i + 1) * 25).toString(),
    id: (i + 1) * 25,
  }));

  const [selectedDevicesQuantity, setSelectedDevicesQuantity] = useState(
    devicesQuantity[1]
  );
  const devicePrice = 35;
  const totalCost = (selectedDevicesQuantity.id * devicePrice).toLocaleString();
  const isNextDisabled =
    (currentStep === ORDER_DEVICES_STEP.ORDER_DEVICES &&
      (isAllLocations ? !selectedShopId : !selectedShop)) ||
    (currentStep === ORDER_DEVICES_STEP.DELIVERY_INFO &&
      (!selectedShopDetails ||
        !selectedDevicesQuantity ||
        !userEmail ||
        !selectedPhone ||
        !selectedCountry ||
        !selectedState ||
        !selectedCity ||
        !selectedStreetAddress ||
        !selectedZipCode));
  const { isMobile } = useScreenSize();
  const modalRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (!isAllLocations) {
      setSelectedShopId(selectedShop.id);
    }
  }, []);

  useEffect(() => {
    if (selectedShopId) {
      setShopIdHeader(selectedShopId);
    }
  }, [selectedShopId]);

  const { handleSubmit, control } = useForm({});

  const onSubmit = async (data) => {
    dispatch(clearOrderError());

    const {
      email,
      addressMain,
      addressAdditional,
      zipCode,
      country,
      state,
      city,
      firstName,
      lastName,
      company,
      phone,
    } = data;

    const orderData = {
      shopId: selectedShopDetails.id,
      tenantId: selectedTenant.id,
      shopName: selectedShopDetails.name,
      confirmationEmail: email,
      quantity: selectedDevicesQuantity.id,
      shippingAddress: {
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phone,
        street1: addressMain,
        street2: addressAdditional,
        city: city,
        region: state?.id,
        postalCode: zipCode,
        country: country?.value,
      },
    };

    if (!selectedShopDetails || !selectedDevicesQuantity || !email || !phone)
      return;

    dispatch(orderDevices(orderData));
  };

  const onToggle = () => {
    onClose();
    setSelectedShopId(null);
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity("");
    setSelectedStreetAddress("");
    setSelectedAdditionalStreet("");
    setSelectedZipCode("");
    dispatch(clearOrderError());
  };

  const handleNextClick = async () => {
    if (isNextDisabled) return;

    if (currentStep === ORDER_DEVICES_STEP.ORDER_DEVICES) {
      dispatch(nextStep());
    } else if (currentStep === ORDER_DEVICES_STEP.DELIVERY_INFO) {
      await handleSubmit(onSubmit)();
    }
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
  };

  useEffect(() => {
    if (!modalRef.current || isMobile) return;

    setIsTransitioning(true);

    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 200);

    return () => clearTimeout(timer);
  }, [currentStep]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        contentClassName={cn(
          classes.Modal__Content,
          classes.Modal__Content__OrderDevice
        )}
        className={classes.Modal__Dialog}
        centered={true}
        backdrop="static"
        ref={modalRef}
        style={{
          transform: isTransitioning ? "scale(0.99)" : "scale(1)",
          transition: "transform 0.2s ease-out",
        }}
      >
        <div className={classes.Modal__Header}>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className={classes.Modal__Body}>
            {currentStep === ORDER_DEVICES_STEP.ORDER_DEVICES && (
              <div className={styles.firstStep}>
                <div className={styles.halfWrapper}>
                  <div className={styles.modalTitle}>Buy More Devices</div>
                  <div className={styles.modalDescription}>
                    In order to buy devices, please fill in the required
                    information.
                  </div>
                  <div className={styles.inputTitle}>Select Location</div>
                  <FormGroup>
                    <Controller
                      name="shop"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={selectedShopDetails}
                      render={({ onChange, value, name, error }) => (
                        <SelectInput
                          label="Shop Location"
                          labelClassName={styles.selectLabel}
                          options={shops}
                          onInputChange={(shop) => {
                            onChange(shop);
                            setSelectedShopId(shop.id);
                          }}
                          value={selectedShopDetails || value}
                          disabled={!isAllLocations}
                          name={name}
                          error={error}
                          getOptionValue={(option) => option.id}
                          formatOptionLabel={(option, { context }) =>
                            option &&
                            option.id && (
                              <ShopLabel
                                name={option.name}
                                shopAddress={joinShopAddress(option, false)}
                                isMenuContext={context === "menu"}
                              />
                            )
                          }
                          className="mb-24"
                        />
                      )}
                    />
                  </FormGroup>
                  <hr className={styles.divider} />
                  <div className={styles.inputTitle}>
                    Select Number of Devices
                  </div>
                  <FormGroup>
                    <Controller
                      name="devicesQuantity"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={selectedDevicesQuantity}
                      render={({ onChange, value, name, error }) => (
                        <SelectInput
                          name={name}
                          label="Number of devices"
                          labelClassName={styles.selectLabel}
                          options={devicesQuantity}
                          onInputChange={(quantity) => {
                            onChange(quantity);
                            setSelectedDevicesQuantity(quantity);
                          }}
                          value={selectedDevicesQuantity || value}
                          disabled={
                            isAllLocations ? !selectedShopId : !selectedShop
                          }
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.label}
                          error={error}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
                <div className={styles.halfWrapper}>
                  <div className={styles.modalTitle}>Pricing</div>
                  {PRICING_DETAILS.map(
                    ({ icon: Icon, title, description }, i) => (
                      <div className={styles.pricingDetails} key={i}>
                        <div className={styles.textWrapper}>
                          <Icon />
                          <div className={styles.detailsTitle}>{title}</div>
                        </div>
                        <div className={styles.detailsDescription}>
                          {description}
                        </div>
                      </div>
                    )
                  )}
                  <hr className={styles.divider} />
                  <div className={styles.pricingDetails}>
                    <div className={styles.detailsDescription}>
                      You will have to pay:
                    </div>
                    <div className={styles.detailsDescription}>
                      <span className={styles.highlighted}>$35</span> per device
                      x{" "}
                      <span className={styles.highlighted}>
                        {selectedDevicesQuantity.id}
                      </span>{" "}
                      devices
                    </div>
                    <div className={styles.info}>
                      <span className={styles.highlighted}>${totalCost}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentStep === ORDER_DEVICES_STEP.DELIVERY_INFO && (
              <div className={styles.secondStep}>
                <div className={styles.modalTitle}>Delivery Information</div>
                <div className={styles.modalDescription}>
                  Make sure that all the information is correct. The information
                  in the disabled inputs comes from the shop settings, if
                  something is inaccurate, please change it in the shop
                  settings.
                </div>
                <div className={styles.inputTitle}>Personal Info</div>
                <div className={styles.formWrapper}>
                  <FormGroup>
                    <Label for="firstName" className={formClasses.InputLabel}>
                      First name
                    </Label>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue={userFirstName || ""}
                      rules={{ required: true }}
                      render={({ error, name }) => (
                        <Input
                          name={name}
                          className={formClasses.Input}
                          id="firstName"
                          error={error}
                          value={userFirstName}
                          disabled
                          readOnly
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="lastName" className={formClasses.InputLabel}>
                      Last name
                    </Label>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue={userLastName || ""}
                      rules={{ required: true }}
                      render={({ error, name }) => (
                        <Input
                          name={name}
                          className={formClasses.Input}
                          id="lastName"
                          error={error}
                          value={userLastName}
                          disabled
                          readOnly
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="company" className={formClasses.InputLabel}>
                      Company
                    </Label>
                    <Controller
                      name="company"
                      control={control}
                      defaultValue={
                        selectedTenant.name || selectedShopDetails.name
                      }
                      rules={{ required: true }}
                      render={({ value, error, name }) => (
                        <Input
                          name={name}
                          className={formClasses.Input}
                          id="company"
                          error={error}
                          value={value || ""}
                          disabled
                          readOnly
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="email" className={formClasses.InputLabel}>
                      Order confirmation email address
                    </Label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={userEmail}
                      render={({ value, error, onChange, name }) => (
                        <Input
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          className={formClasses.Input}
                          id="email"
                          error={error}
                          value={value || userEmail}
                          disabled
                          readOnly
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="phone" className={formClasses.InputLabel}>
                      Phone number
                    </Label>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={selectedPhone}
                      render={({ value, error, onChange, name }) => (
                        <PhoneInput
                          name={name}
                          id="phone"
                          onChange={(phone) => {
                            onChange(phone);
                            setSelectedPhone(phone);
                          }}
                          placeholder="Enter phone number"
                          error={error}
                          value={selectedPhone || value}
                        />
                      )}
                    />
                  </FormGroup>
                </div>

                <hr className={styles.divider} />
                <div className={styles.inputTitle}>Mailing Address</div>
                <div className={styles.formWrapper}>
                  <FormGroup>
                    <Label for="addressMain" className={formClasses.InputLabel}>
                      Address line 1
                    </Label>
                    <Controller
                      name="addressMain"
                      control={control}
                      defaultValue={selectedStreetAddress}
                      render={({ value, error, onChange, name }) => (
                        <Input
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                            setSelectedStreetAddress(e.target.value);
                          }}
                          className={formClasses.Input}
                          id="addressMain"
                          error={error}
                          value={selectedStreetAddress || value}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="addressAdditional"
                      className={formClasses.InputLabel}
                    >
                      Address line 2
                      <span className={formClasses.InputLabel__Optional__Order}>
                        (optional)
                      </span>
                    </Label>
                    <Controller
                      name="addressAdditional"
                      control={control}
                      defaultValue={selectedAdditionalStreet}
                      render={({ value, error, onChange, name }) => (
                        <Input
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                            setSelectedAdditionalStreet(e.target.value);
                          }}
                          className={formClasses.Input}
                          id="addressAdditional"
                          error={error}
                          value={selectedAdditionalStreet || value}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="zipCode" className={formClasses.InputLabel}>
                      Zip
                    </Label>
                    <Controller
                      name="zipCode"
                      control={control}
                      defaultValue={selectedZipCode}
                      render={({ value, error, onChange, name }) => (
                        <Input
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                            setSelectedZipCode(e.target.value);
                          }}
                          className={formClasses.Input}
                          id="zip"
                          error={error}
                          value={selectedZipCode || value}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={selectedCountry}
                      render={({ onChange, value, name, error }) => (
                        <SelectInput
                          label="Country / region"
                          labelClassName={styles.selectLabel}
                          options={COUNTRIES}
                          onInputChange={(country) => {
                            onChange(country);
                            handleCountryChange(country);
                          }}
                          value={selectedCountry || value}
                          name={name}
                          error={error}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue={selectedState}
                      render={({ onChange, value, name, error }) => (
                        <SelectInput
                          label="State"
                          labelClassName={styles.selectLabel}
                          options={STATES[selectedCountry?.value] || []}
                          onInputChange={(state) => {
                            onChange(state);
                            setSelectedState(state);
                          }}
                          value={selectedState}
                          name={name}
                          error={error}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.label}
                          menuPlacement="top"
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="city"
                      className={cn(
                        formClasses.InputLabel,
                        styles.cityInputLabel
                      )}
                    >
                      City
                    </Label>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue={selectedCity}
                      render={({ onChange, value, name, error }) => (
                        <Input
                          name={name}
                          onChange={(e) => {
                            onChange(e.target.value);
                            setSelectedCity(e.target.value);
                          }}
                          className={formClasses.Input}
                          id="city"
                          error={error}
                          value={selectedCity || value}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            )}

            {currentStep === ORDER_DEVICES_STEP.SUMMARY && (
              <div className={styles.thirdStep}>
                <div className={styles.successHeader}>
                  <SuccessIcon />
                  <div className={styles.info}>Success!</div>
                  <div
                    className={cn(
                      styles.modalDescription,
                      styles.successDescription
                    )}
                  >
                    Your order has been successfully submitted. Thank you for
                    your purchase! We will contact you after order confirmation
                    to finalize the details and provide further instructions.
                  </div>
                </div>
                <div className={styles.totalWrapper}>
                  <div className={styles.totalLabel}>Total</div>
                  <hr className={cn(styles.divider, styles.noSpace)} />
                  <div className={styles.totalInfo}>
                    <div className={styles.totalCard}>
                      <DTCIcon />
                      <div className={styles.totalCardInfo}>
                        <div className={styles.totalCardTitle}>
                          Number of Devices
                        </div>
                        <div className={styles.totalCardNumber}>
                          {selectedDevicesQuantity.id}
                        </div>
                      </div>
                    </div>
                    <div className={styles.totalCard}>
                      <SalesIcon />
                      <div className={styles.totalCardInfo}>
                        <div className={styles.totalCardTitle}>Price</div>
                        <div className={styles.totalCardNumber}>
                          ${totalCost}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.totalWrapper}>
                  <div className={styles.totalLabel}>Delivery Information</div>
                  <hr className={cn(styles.divider, styles.noSpace)} />
                  <div className={styles.deliveryInfo}>
                    <div className={styles.summary}>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>
                          First name
                        </div>
                        <div className={styles.summaryItemValue}>
                          {userFirstName}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>Last name</div>
                        <div className={styles.summaryItemValue}>
                          {userLastName}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>Company</div>
                        <div className={styles.summaryItemValue}>
                          {selectedTenant.name || selectedShop.name}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>
                          Phone number
                        </div>
                        <div className={styles.summaryItemValue}>
                          {formatPhoneWithoutCountry(selectedPhone)}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>
                          Order confirmation email address
                        </div>
                        <div className={styles.summaryItemValue}>
                          {userEmail}
                        </div>
                      </div>
                    </div>
                    <div className={styles.summary}>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>
                          Address line 1
                        </div>
                        <div className={styles.summaryItemValue}>
                          {selectedStreetAddress}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>
                          Country / region
                        </div>
                        <div className={styles.summaryItemValue}>
                          {selectedCountry?.label}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>State</div>
                        <div className={styles.summaryItemValue}>
                          {selectedState?.label}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>City</div>
                        <div className={styles.summaryItemValue}>
                          {selectedCity}
                        </div>
                      </div>
                      <div className={styles.summaryItem}>
                        <div className={styles.summaryItemTitle}>Zip</div>
                        <div className={styles.summaryItemValue}>
                          {selectedZipCode}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
          {currentStep !== ORDER_DEVICES_STEP.SUMMARY && (
            <>
              <hr
                className={cn(
                  classes.Modal_HorizontalLine,
                  classes.Modal_HorizontalLine__OrderDevices
                )}
              />
              <ModalFooter
                className={cn(
                  classes.Modal__Footer,
                  classes.Modal__Footer__OrderDevices
                )}
              >
                <ReactTooltip
                  className={styles.tooltipOrderButton}
                  key={isNextDisabled}
                  id="tooltipOrderButton"
                />
                {isOrderError &&
                  currentStep === ORDER_DEVICES_STEP.DELIVERY_INFO && (
                    <Alert color="danger" className={styles.errorAlert}>
                      <AttentionIconRed className="WarningIcon" />
                      {isOrderError}
                    </Alert>
                  )}
                <button
                  className={cn(
                    classes.Modal__CancelBtn,
                    classes.Modal__CancelBtn__OrderDevices
                  )}
                  onClick={() =>
                    currentStep === ORDER_DEVICES_STEP.ORDER_DEVICES
                      ? onToggle()
                      : dispatch(prevStep())
                  }
                >
                  {currentStep === ORDER_DEVICES_STEP.ORDER_DEVICES && "Cancel"}
                  {currentStep === ORDER_DEVICES_STEP.DELIVERY_INFO && (
                    <>
                      <ArrowBackIcon className={styles.arrowBack} />
                      Back
                    </>
                  )}
                </button>
                <button
                  type="button"
                  className={cn(
                    classes.Modal__SubmitBtn,
                    classes.Modal__SubmitBtn__OrderDevices,
                    { [classes.Modal__SubmitBtn__Disabled]: isNextDisabled }
                  )}
                  onClick={() => !isOrderLoading && handleNextClick()}
                  data-tip={
                    isNextDisabled
                      ? "Please fill in all required fields to continue"
                      : null
                  }
                  data-for="tooltipOrderButton"
                >
                  {isOrderLoading ? (
                    <LoaderIcon className={classes.Modal__Loader} />
                  ) : (
                    <>
                      Next
                      <ArrowBackIcon className={styles.arrowNext} />
                    </>
                  )}
                </button>
              </ModalFooter>
            </>
          )}
        </form>
      </Modal>
    </div>
  );
}

function ShopLabel({ name, shopAddress, isMenuContext }) {
  return (
    <div className={cn(styles.shopItem, { [styles.menuItem]: isMenuContext })}>
      <span className={styles.shopItemName}>{name}</span>
      {!isMenuContext && <span className={styles.shopItemDot}>•</span>}
      <span className={styles.shopItemAddress}>{shopAddress}</span>
    </div>
  );
}
