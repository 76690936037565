import TractionDisabled from "../src/assets/icons/warnings/traction_disabled.svg";
import TransmissionOil from "../src/assets/icons/warnings/transmission_oil.svg";
import EngineTemperature from "../src/assets/icons/warnings/engine_temperature.svg";
import LowOil from "../src/assets/icons/warnings/oil.svg";
import EngineStarting from "../src/assets/icons/warnings/engine_starting.svg";
import EngineOperating from "../src/assets/icons/warnings/engine_operating.svg";
import CheckFuel from "../src/assets/icons/warnings/check_fuel.svg";
import ChangeCleanFilter from "../src/assets/icons/warnings/change_clean_filter.svg";
import CheckEngine from "../src/assets/icons/warnings/check_engine.svg";
import WaterDeteted from "../src/assets/icons/warnings/water_detected.svg";
import ChangeFuel from "../src/assets/icons/warnings/change_fuel.svg";
import FuelTrim from "../src/assets/icons/warnings/fuel_trim.svg";
import OxygenSensor from "../src/assets/icons/warnings/oxygen_sensor.svg";
import Battery from "../src/assets/icons/warnings/battery.svg";
import AirFilter from "../src/assets/icons/warnings/air-filter.svg";

import CheckEngineIcon from "../src/assets/icons/alerts/check-engine-light.svg";
import WarningLightIcon from "../src/assets/icons/alerts/warning-light.svg";
import WipersOnIcon from "../src/assets/icons/alerts/wipers-on.svg";
import ABSActiveIcon from "../src/assets/icons/alerts/abs-active.svg";
import AWDEngagedIcon from "../src/assets/icons/alerts/4wd-engaged.svg";
import CoolantTempIcon from "../src/assets/icons/alerts/coolant-temp.svg";
import TransmissionOilTempIcon from "../src/assets/icons/alerts/transmission-oil-temp.svg";
import DeviceVoltageIcon from "../src/assets/icons/alerts/device-voltage.svg";
import FuelLevelIcon from "../src/assets/icons/alerts/fuel-level-total-fuel-used.svg";
import RawOutsideAirTempIcon from "../src/assets/icons/alerts/raw-outside-air-temp.svg";
import RPMIcon from "../src/assets/icons/alerts/rpm.svg";
import OdometerSpeedIcon from "../src/assets/icons/alerts/odometer-speed.svg";
import FuelFilterLifeRemainingIcon from "../src/assets/icons/alerts/fuel-filter-life-remaining.svg";
import FuelAlcoholContentIcon from "../src/assets/icons/alerts/fuel-alcohol-content.svg";
import BarometricPressureIcon from "../src/assets/icons/alerts/barometric-pressure.svg";
import TirePressureIcon from "../src/assets/icons/alerts/tpms.svg";
import BatteryVoltageIcon from "../src/assets/icons/alerts/battery-voltage.svg";
import GearPositionIcon from "../src/assets/icons/alerts/gear-position.svg";
import ACRefrigerantMonitorIcon from "../src/assets/icons/alerts/ac-refrigerant-monitor.svg";
import CatalystMonitorIcon from "../src/assets/icons/alerts/catalyst-monitor-heated-catalyst-monitor.svg";
import ComponentsMisfireMonitorIcon from "../src/assets/icons/alerts/component-misfire-monitor.svg";
import EGRSystemMonitorIcon from "../src/assets/icons/alerts/egr-system-monitor.svg";
import EvaporativeSystemMonitorIcon from "../src/assets/icons/alerts/evaporative-system-monitor.svg";
import FuelSystemMonitorIcon from "../src/assets/icons/alerts/fuel-system-monitor.svg";
import OxygenSensorMonitorIcon from "../src/assets/icons/alerts/oxygen-sensor-heater-monitor.svg";
import SecondaryAirSystemMonitorIcon from "../src/assets/icons/alerts/secondary-air-system-monitor.svg";
import PMFilterMonitorIcon from "../src/assets/icons/alerts/pm-filter-monitor.svg";
import ExhaustGasSensorIcon from "../src/assets/icons/alerts/exhaust-gas-sensor.svg";
import NOxSCRMonitorIcon from "../src/assets/icons/alerts/nox-scr-monitor.svg";
import IgnitionIcon from "../src/assets/icons/alerts/ignition.svg";
import EngineOilLifeIcon from "../src/assets/icons/alerts/engine-oil-life.svg";

import { alertsConvertMethods } from "./utils";

export const CUSTOM_LABELS = {
  "Telematics device voltage": "Device Voltage",
  "Engine road speed": "Speed",
  "Engine speed": "RPM",
  "Windshield wipers on": "Wipers ON",
  "4WD engaged (1 = engaged)": "4WD Engaged",
  "Engine transmission oil temperature": "Transmission Oil Temp.",
  "Cranking voltage": "Battery Voltage",
  "Engine coolant temperature": "Coolant Temp.",
  "Barometric pressure": "Barometric Pressure",
  "Tire pressure - rear left": "TPMS - Rear Left",
  "Tire pressure - front left": "TPMS - Front Left",
  "Tire pressure - rear right": "TPMS - Rear Right",
  "Tire pressure - front right": "TPMS - Front Right",
  "Generic ABS active": "ABS Active",
  "Outside air temperature": "Outside Temp.",
  "Fuel level (volume)": "Fuel Level",
  "Engine oil temperature": "Engine Oil Temp.",
  "Generic oxygen sensor bank 1 sensor 1 voltage":
    "Oxygen Sensor bank 1 sensor 1",
  "Generic oxygen sensor bank 1 sensor 2 voltage":
    "Oxygen Sensor bank 1 sensor 2",
  "Generic oxygen sensor bank 1 sensor 3 voltage":
    "Oxygen Sensor bank 1 sensor 3",
  "Generic oxygen sensor bank 1 sensor 4 voltage":
    "Oxygen Sensor bank 1 sensor 4",
  "Generic oxygen sensor bank 2 sensor 1 voltage":
    "Oxygen Sensor bank 2 sensor 1",
  "Generic oxygen sensor bank 2 sensor 2 voltage":
    "Oxygen Sensor bank 2 sensor 2",
  "Generic oxygen sensor bank 2 sensor 3 voltage":
    "Oxygen Sensor bank 2 sensor 3",
  "Generic oxygen sensor bank 2 sensor 4 voltage":
    "Oxygen Sensor bank 2 sensor 4",
  "Generic long term fuel trim bank 1": "Fuel Trim long term bank 1",
  "Generic long term fuel trim bank 2": "Fuel Trim long term bank 2",
  "Generic short term fuel trim bank 1": "Fuel Trim short term bank 1",
  "Generic short term fuel trim bank 2": "Fuel Trim short term bank 2",
  "Ignition state": "Ignition State",
  "Engine oil life": "Engine Oil Life",
};

export const WHITELIST = [
  "Device Voltage",
  "RPM",
  "Speed",
  "Wipers ON",
  "4WD Engaged",
  "Transmission Oil Temp.",
  "Battery Voltage",
  "Coolant Temp.",
  "Barometric Pressure",
  "TPMS - Rear Left",
  "TPMS - Front Left",
  "TPMS - Rear Right",
  "TPMS - Front Right",
  "ABS Active",
  "Outside Temp.",
  "Fuel Level",
  "Engine Oil Temp.",
  "Oxygen Sensor bank 1 sensor 1",
  "Oxygen Sensor bank 1 sensor 2",
  "Oxygen Sensor bank 1 sensor 3",
  "Oxygen Sensor bank 1 sensor 4",
  "Oxygen Sensor bank 2 sensor 1",
  "Oxygen Sensor bank 2 sensor 2",
  "Oxygen Sensor bank 2 sensor 3",
  "Oxygen Sensor bank 2 sensor 4",
  "Fuel Trim long term bank 1",
  "Fuel Trim long term bank 2",
  "Fuel Trim short term bank 1",
  "Fuel Trim short term bank 2",
  "Ignition State",
  "Engine Oil Life",
];

export const WARNINGS = {
  DiagnosticTractionControlSystemEnabledId: {
    icon: TractionDisabled,
    label: "Traction Control System Disabled",
    color: "yellow",
    isOtherAlert: true,
  },
  DiagnosticTransmissionOilChangeWarningLightId: {
    icon: TransmissionOil,
    label: "Transmission Oil Change",
    color: "yellow",
    isOtherAlert: true,
  },
  DiagnosticEngineHotWarningLightId: {
    icon: EngineTemperature,
    label: "Engine Temperature Warning Light",
    color: "red",
  },
  DiagnosticOilLevelLowWarningLightId: {
    icon: LowOil,
    label: "Low Oil Level",
    color: "yellow",
  },
  DiagnosticOilLightOnId: {
    icon: LowOil,
    label: "Oil Warning Light",
    color: "yellow",
  },
  DiagnosticOilChangeDueWarningLightId: {
    icon: LowOil,
    label: "Oil change due",
    color: "yellow",
  },
  DiagnosticEngineStartingDisabledWarningLightId: {
    icon: EngineStarting,
    label: "Engine Starting Disabled",
    color: "yellow",
    isOtherAlert: true,
  },
  DiagnosticEngineOperatingInReducedPowerModeWarningLightId: {
    icon: EngineOperating,
    label: "Engine Operating in Reduced Power Mode",
    color: "red",
  },
  DiagnosticEngineCheckFuelFillerCapWarningLightId: {
    icon: CheckFuel,
    label: "Check Fuel Filler Cap",
    color: "yellow",
    isOtherAlert: true,
  },
  DiagnosticChangeCleanExhaustFilterWarningLightId: {
    icon: ChangeCleanFilter,
    label: "Change Clean Exhaust Filter",
    color: "yellow",
    isOtherAlert: true,
  },
  DiagnosticEngineWarningLightId: {
    icon: CheckEngine,
    label: "Check Engine Light",
    color: "yellow",
  },
  DiagnosticWaterDetectedInFuelWarningLightId: {
    icon: WaterDeteted,
    label: "Water Detected in Fuel",
    color: "yellow",
  },
  DiagnosticChangeFuelFilterWarningLightId: {
    icon: ChangeFuel,
    label: "Change Fuel Filter",
    color: "yellow",
    isOtherAlert: true,
  },
  battery: {
    icon: Battery,
    label: "Battery Charge Warning Light",
    color: "yellow",
  },
  airFilter: {
    icon: AirFilter,
    label: "Clogged Air Filter",
    color: "yellow",
    isOtherAlert: true,
  },
  oil: {
    icon: LowOil,
    label: "Oil Warning Light",
    color: "yellow",
  },
};
export const PREDICTION_WARNINGS = {
  fuelTrim: {
    icon: FuelTrim,
    label: "Fuel Trim",
    isOtherAlert: true,
  },
  oxygenSensor: {
    icon: OxygenSensor,
    label: "Oxygen Sensor",
    isOtherAlert: true,
  },
};

export const booleanTemplateAlerts = {
  CheckEngine: false,
  WarningLight: false,
};

export const diagnosticOptionsToAlerts = {
  aHI_G0yzIvEWYLDRM9VEKIA: "WipersOn",
  aG3QE28EtpUSvjLEUXAGLKw: "ABSActive",
  "agZZT2jBQrUOVC-B3JOUxcA": "AWDEngaged",
  DiagnosticEngineCoolantTemperatureId: "CoolantTemp",
  DiagnosticEngineTransmissionOilTemperatureId: "TransmissionOilTemp",
  DiagnosticGoDeviceVoltageId: "DeviceVoltage",
  DiagnosticFuelLevelId: "FuelLevel",
  aKV1DLB7BYEC2fKN0BmVMTQ: "RawOutsideAirTemp",
  DiagnosticEngineSpeedId: "RPM",
  DiagnosticOdometerId: "Odometer",
  DiagnosticFuelFilterLifeRemainingId: "FuelFilterLifeRemaining",
  DiagnosticEngineRoadSpeedId: "Speed",
  DiagnosticFuelAlcoholCompositionId: "FuelAlcoholContent",
  anhSofTlT20ayntgV7IvW8A: "BarometricPressure",
  DiagnosticTirePressureFrontRightId: "TPMSFrontRight",
  DiagnosticTirePressureFrontLeftId: "TPMSFrontLeft",
  DiagnosticTirePressureRearRightId: "TPMSBackRight",
  DiagnosticTirePressureRearLeftId: "TPMSBackLeft",
  DiagnosticDeviceTotalIdleFuelId: "TotalFuelUsedWhileIdling",
  DiagnosticTotalTripIdleFuelUsedId: "TripIdleFuelUsed",
  DiagnosticTotalTripFuelUsedId: "TripFuelUsed",
  DiagnosticCrankingVoltageId: "BatteryVoltage",
  DiagnosticGearPositionId: "GearPosition",
  am8Z_jLXQV0qHrnC89vs8xA: "OxygenSensor",
  awSxnb03aCEOOVAGXJbenPA: "OxygenSensor",
  abBrjk_PtS0y0jNqTQ8lT3A: "OxygenSensor",
  "aUgvVik0c30-VPcgZa_lhDg": "OxygenSensor",
  aVC5Kq2F0Q0GGV0CMrfcKSg: "OxygenSensor",
  a1tjDLOCNO0Ssj_kZpvjudA: "OxygenSensor",
  "aLddtThK7-UCV5xmI_Hfkew": "OxygenSensor",
  a8bYFqXSJ0kyRm1bpawnihQ: "OxygenSensor",
  "a5zyt7QRq-0ePeA-JJ_TXKQ": "FuelTrimLong",
  "avdQTO9YdxU-yGeVQ7-VAVQ": "FuelTrimLong",
  atNIVoFT1cUq05TPxOBaTzQ: "FuelTrimShort",
  "aFk857DxaM0CC-svWzqqp6Q": "FuelTrimShort",
  //
  fuelLevel: "FuelLevel",
  odometer: "Odometer",
  speed: "Speed",
  batteryVoltage: "BatteryVoltage",
  OxygenSensorBank1Sensor1: "OxygenSensor",
  OxygenSensorBank1Sensor2: "OxygenSensor",
  OxygenSensorBank1Sensor3: "OxygenSensor",
  OxygenSensorBank1Sensor4: "OxygenSensor",
  OxygenSensorBank2Sensor1: "OxygenSensor",
  OxygenSensorBank2Sensor2: "OxygenSensor",
  OxygenSensorBank2Sensor3: "OxygenSensor",
  OxygenSensorBank2Sensor4: "OxygenSensor",
  FuelTrimShortBank1: "FuelTrimShort",
  FuelTrimShortBank2: "FuelTrimShort",
  FuelTrimLongBank1: "FuelTrimLong",
  FuelTrimLongBank2: "FuelTrimLong",
  coolantTemp: "CoolantTemp",
  tirePressureFrontLeft: "TPMSFrontLeft",
  tirePressureFrontRight: "TPMSFrontRight",
  tirePressureRearLeft: "TPMSBackLeft",
  tirePressureRearRight: "TPMSBackRight",
  ignition: "IgnitionOn",
  engineOilLife: "EngineOilLife",
};

export const diagnosticOptionsToAggregationAlerts = {
  DiagnosticTirePressureFrontRightId: "TPMSAllTires",
  DiagnosticTirePressureFrontLeftId: "TPMSAllTires",
  DiagnosticTirePressureRearRightId: "TPMSAllTires",
  DiagnosticTirePressureRearLeftId: "TPMSAllTires",
  tirePressureFrontLeft: "TPMSAllTires",
  tirePressureFrontRight: "TPMSAllTires",
  tirePressureRearRight: "TPMSAllTires",
  tirePressureRearLeft: "TPMSAllTires",
};

export const monitorsToAlerts = {
  "AC refrigerant monitor": "ACRefrigerantMonitor",
  "catalyst monitor": "CatalystMonitor",
  "components monitor": "ComponentsMonitor",
  "EGR system monitor": "EGRSystemMonitor",
  "evaporative system monitor": "EvaporativeSystemMonitor",
  "fuel system monitor": "FuelSystemMonitor",
  "heated catalyst monitor": "HeatedCatalystMonitor",
  "misfire monitor": "MisfireMonitor",
  "oxygen sensor heater monitor": "OxygenSensorHeaterMonitor",
  "oxygen sensor monitor": "OxygenSensorMonitor",
  "secondary air system monitor": "SecondaryAirSystemMonitor",
  //
  Components: "ComponentsMonitor",
  "Fuel System": "FuelSystemMonitor",
  Misfire: "MisfireMonitor",
  "EGR System": "EGRSystemMonitor",
  "Oxygen Sensor Heater": "OxygenSensorHeaterMonitor",
  "Oxygen Sensor": "OxygenSensorMonitor",
  "AC Refrigerant": "ACRefrigerantMonitor",
  "Secondary Air System": "SecondaryAirSystemMonitor",
  "Evaporative System": "EvaporativeSystemMonitor",
  "Heated Catalyst": "HeatedCatalystMonitor",
  Catalyst: "CatalystMonitor",
  "EGR/VVT System": "EGRVVTSystemMonitor",
  "PM Filter Monitoring": "PMFilterMonitor",
  "Exhaust Gas Sensor": "ExhaustGasSensor",
  "Boost Pressure": "BoostPressureMonitor",
  "NOx/SCR Monitor": "NOxSCRMonitor",
  "NMHC Catalyst": "NMHCCatalyst",
};

export const defaultAlertSilenceTime = 86400000; // 24 hours

export const alertsParams = {
  CheckEngine: {
    name: "Check Engine Light",
    description:
      "Receive check engine alerts for when the engine light goes ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} has an engine light that has changed to the status of {value} in their {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected a change in the status of your engine light in {vehicleName}. Status is currently {value}. Please call us to discuss the issue.",
    },
    icon: CheckEngineIcon,
  },
  WarningLight: {
    name: "Warning Light",
    description:
      "Receive warning light alerts for when a warning light goes ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} has a warning light that has changed to the status of {value} in their {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected a change in the status of your warning light in {vehicleName}. Status is currently {value}. Please call us to discuss the issue.",
    },
    icon: WarningLightIcon,
  },
  WipersOn: {
    name: "Wipers On",
    description: "Receive alerts when the wiper blades go ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} has wipers that have changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that your wipers in {vehicleName} has changed to a status of {value}. Please call us to discuss the issue.",
    },
    icon: WipersOnIcon,
  },
  ABSActive: {
    name: "ABS Active",
    description: "Receive alerts when the ABS goes ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} ABS has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that your ABS in {vehicleName} has changed to a status of {value}. Please call us to discuss the issue.",
    },
    icon: ABSActiveIcon,
  },
  AWDEngaged: {
    name: "4WD Engaged",
    description: "Receive alerts when 4WD goes ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} 4WD has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that your 4WD in {vehicleName} has changed to a status of {value}. Please call us to discuss the issue.",
    },
    icon: AWDEngagedIcon,
  },
  CoolantTemp: {
    name: "Coolant Temp",
    triggerType: "range",
    triggerRangeImperial: [0, 500],
    triggerRangeMetric: [-20, 260],
    measurementUnits: ["°C", "°F"],
    convertMetricToImperial: alertsConvertMethods.convertCelsiusToFahrenheit,
    convertImperialToMetric: alertsConvertMethods.convertFahrenheitToCelsius,
    textTemplates: {
      shop: "Alert: {customerName} has a Coolant Temperature of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Coolant Temperature in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: CoolantTempIcon,
  },
  TransmissionOilTemp: {
    name: "Transmission Oil Temp",
    triggerType: "range",
    triggerRangeImperial: [0, 500],
    triggerRangeMetric: [-20, 260],
    measurementUnits: ["°C", "°F"],
    convertMetricToImperial: alertsConvertMethods.convertCelsiusToFahrenheit,
    convertImperialToMetric: alertsConvertMethods.convertFahrenheitToCelsius,
    textTemplates: {
      shop: "Alert: {customerName} has a Transmission Oil Temperature of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Transmission Oil Temperature in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: TransmissionOilTempIcon,
  },
  DeviceVoltage: {
    name: "Device Voltage",
    triggerType: "range",
    triggerRangeImperial: [0, 50],
    triggerRangeMetric: [0, 50],
    measurementUnits: ["Volts", "Volts"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Device Voltage of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Device Voltage in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: DeviceVoltageIcon,
  },
  FuelLevel: {
    name: "Fuel Level",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Fuel Level of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Fuel Level in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: FuelLevelIcon,
  },
  RawOutsideAirTemp: {
    name: "Raw Outside Air Temp",
    triggerType: "range",
    triggerRangeImperial: [-100, 200],
    triggerRangeMetric: [-100, 100],
    measurementUnits: ["°C", "°F"],
    convertMetricToImperial: alertsConvertMethods.convertCelsiusToFahrenheit,
    convertImperialToMetric: alertsConvertMethods.convertFahrenheitToCelsius,
    textTemplates: {
      shop: "Alert: {customerName} has a Outside Air Temperature of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Outside Air Temperature in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: RawOutsideAirTempIcon,
  },
  RPM: {
    name: "RPM",
    triggerType: "range",
    triggerRangeImperial: [0, 25000],
    triggerRangeMetric: [0, 25000],
    measurementUnits: ["RPM", "RPM"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a RPM of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the RPM in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: RPMIcon,
  },
  Odometer: {
    name: "Odometer",
    triggerType: "range",
    triggerRangeImperial: [0, 1000000],
    triggerRangeMetric: [0, 1000000000],
    measurementUnits: ["Meters", "Miles"],
    convertMetricToImperial: alertsConvertMethods.convertMetersToMiles,
    convertImperialToMetric: alertsConvertMethods.convertMilesToMeters,
    textTemplates: {
      shop: "Alert: {customerName} has a Odometer of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Odometer in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: OdometerSpeedIcon,
  },
  FuelFilterLifeRemaining: {
    name: "Fuel Filter Life Remaining",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Fuel Filter Life of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Fuel Filter Life in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: FuelFilterLifeRemainingIcon,
  },
  Speed: {
    name: "Speed",
    triggerType: "range",
    triggerRangeImperial: [0, 250],
    triggerRangeMetric: [0, 400],
    measurementUnits: ["KPH", "MPH"],
    convertMetricToImperial: alertsConvertMethods.convertKPHToMPH,
    convertImperialToMetric: alertsConvertMethods.convertMPHToKPH,
    textTemplates: {
      shop: "Alert: {customerName} has a MPH of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the MPH in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: OdometerSpeedIcon,
  },
  FuelAlcoholContent: {
    name: "Fuel Alcohol Content",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Fuel Alcohol Content of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Fuel Alcohol Content in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: FuelAlcoholContentIcon,
  },
  BarometricPressure: {
    name: "Barometric Pressure",
    triggerType: "range",
    triggerRangeImperial: [20, 40],
    triggerRangeMetric: [60000, 150000],
    measurementUnits: ["Pascals", "inHg"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToInHg,
    convertImperialToMetric: alertsConvertMethods.convertInHgToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has a Barometer Pressure of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Barometer Pressure in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: BarometricPressureIcon,
  },
  TPMSFrontRight: {
    name: "TPMS Front Right",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 700000],
    measurementUnits: ["Pascals", "PSI"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToPSI,
    convertImperialToMetric: alertsConvertMethods.convertPSIToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has an Air Pressure Reading of {value} in the Front Right Tire of {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Air Pressure in {vehicleName} is {value} in the Front Right Tire. Please call us to discuss the issue.",
    },
    icon: TirePressureIcon,
  },
  TPMSFrontLeft: {
    name: "TPMS Front Left",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 700000],
    measurementUnits: ["Pascals", "PSI"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToPSI,
    convertImperialToMetric: alertsConvertMethods.convertPSIToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has an Air Pressure Reading of {value} in the Front Left Tire of {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Air Pressure in {vehicleName} is {value} in the Front Left Tire. Please call us to discuss the issue.",
    },
    icon: TirePressureIcon,
  },
  TPMSBackRight: {
    name: "TPMS Back Right",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 700000],
    measurementUnits: ["Pascals", "PSI"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToPSI,
    convertImperialToMetric: alertsConvertMethods.convertPSIToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has an Air Pressure Reading of {value} in the Back Right Tire of {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Air Pressure in {vehicleName} is {value} in the Back Right Tire. Please call us to discuss the issue.",
    },
    icon: TirePressureIcon,
  },
  TPMSBackLeft: {
    name: "TPMS Back Left",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 700000],
    measurementUnits: ["Pascals", "PSI"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToPSI,
    convertImperialToMetric: alertsConvertMethods.convertPSIToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has an Air Pressure Reading of {value} in the Back Left Tire of {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Air Pressure in {vehicleName} is {value} in the Back Left Tire. Please call us to discuss the issue.",
    },
    icon: TirePressureIcon,
  },
  TPMSAllTires: {
    name: "TPMS All Tires",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 700000],
    measurementUnits: ["Pascals", "PSI"],
    convertMetricToImperial: alertsConvertMethods.convertPascalsToPSI,
    convertImperialToMetric: alertsConvertMethods.convertPSIToPascals,
    textTemplates: {
      shop: "Alert: {customerName} has an Air Pressure Reading of {value} in one of the Tires of {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Air Pressure in {vehicleName} is {value} in one of the Tires. Please call us to discuss the issue.",
    },
    icon: TirePressureIcon,
  },
  TotalFuelUsedWhileIdling: {
    name: "Total Fuel Used While Idling",
    triggerType: "range",
    triggerRangeImperial: [0, 10000],
    triggerRangeMetric: [0, 40000],
    measurementUnits: ["Liters", "Gallons"],
    convertMetricToImperial: alertsConvertMethods.convertLitersToGallons,
    convertImperialToMetric: alertsConvertMethods.convertGallonsToLiters,
    textTemplates: {
      shop: "Alert: {customerName} has used {value} while idling in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that  {vehicleName} has used {value} while idling. Please call us to discuss the issue.",
    },
    icon: FuelLevelIcon,
  },
  TripIdleFuelUsed: {
    name: "Trip Idle Fuel Used",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 400],
    measurementUnits: ["Liters", "Gallons"],
    convertMetricToImperial: alertsConvertMethods.convertLitersToGallons,
    convertImperialToMetric: alertsConvertMethods.convertGallonsToLiters,
    textTemplates: {
      shop: "Alert: {customerName} has used {value} while idling in {vehicleName} on the current trip.",
      customer:
        "Hi, this is {shopName} we have detected that  {vehicleName} has used {value} while idling on the current trip. Please call us to discuss the issue.",
    },
    icon: FuelLevelIcon,
  },
  TripFuelUsed: {
    name: "Trip Fuel Used",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 400],
    measurementUnits: ["Liters", "Gallons"],
    convertMetricToImperial: alertsConvertMethods.convertLitersToGallons,
    convertImperialToMetric: alertsConvertMethods.convertGallonsToLiters,
    textTemplates: {
      shop: "Alert: {customerName} has used {value} while driving in {vehicleName} on the current trip.",
      customer:
        "Hi, this is {shopName} we have detected that  {vehicleName} has used {value} while driving on the current trip. Please call us to discuss the issue.",
    },
    icon: FuelLevelIcon,
  },
  BatteryVoltage: {
    name: "Battery Voltage",
    triggerType: "range",
    triggerRangeImperial: [0, 50],
    triggerRangeMetric: [0, 50],
    measurementUnits: ["Volts", "Volts"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Battery Voltage of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Battery Voltage in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: BatteryVoltageIcon,
  },
  GearPosition: {
    name: "Gear Position",
    triggerType: "range",
    triggerRangeImperial: [-150, 150],
    triggerRangeMetric: [-150, 150],
    measurementUnits: ["Gears", "Gears"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Gear Position of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Gear Position in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: GearPositionIcon,
  },
  ACRefrigerantMonitor: {
    name: "AC Refrigerant Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} AC Refrigerant Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} AC Refrigerant Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: ACRefrigerantMonitorIcon,
  },
  CatalystMonitor: {
    name: "Catalyst Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Catalyst Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Catalyst Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: CatalystMonitorIcon,
  },
  ComponentsMonitor: {
    name: "Components Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Components Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Components Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: ComponentsMisfireMonitorIcon,
  },
  EGRSystemMonitor: {
    name: "EGR System Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} EGR System Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} EGR System Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: EGRSystemMonitorIcon,
  },
  EvaporativeSystemMonitor: {
    name: "Evaporative System Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Evaporative System Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Evaporative System Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: EvaporativeSystemMonitorIcon,
  },
  FuelSystemMonitor: {
    name: "Fuel System Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Fuel System Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Fuel System Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: FuelSystemMonitorIcon,
  },
  HeatedCatalystMonitor: {
    name: "Heated Catalyst Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Heated Catalyst Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Heated Catalyst Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: CatalystMonitorIcon,
  },
  MisfireMonitor: {
    name: "Misfire Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Misfire Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Misfire Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: ComponentsMisfireMonitorIcon,
  },
  OxygenSensorHeaterMonitor: {
    name: "Oxygen Sensor Heater Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Oxygen Sensor Heater Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Oxygen Sensor Heater Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: OxygenSensorMonitorIcon,
  },
  OxygenSensorMonitor: {
    name: "Oxygen Sensor Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Oxygen Sensor Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Oxygen Sensor Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: OxygenSensorMonitorIcon,
  },
  SecondaryAirSystemMonitor: {
    name: "Secondary Air System Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Secondary Air System Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Secondary Air System Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: SecondaryAirSystemMonitorIcon,
  },
  EGRVVTSystemMonitor: {
    name: "EGR/VVT System Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} EGR/VVT System Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} EGR/VVT System Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: EGRSystemMonitorIcon,
  },
  PMFilterMonitor: {
    name: "PM Filter Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} PM Filter Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} PM Filter Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: PMFilterMonitorIcon,
  },
  ExhaustGasSensor: {
    name: "Exhaust Gas Sensor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Exhaust Gas Sensor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Exhaust Gas Sensor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: ExhaustGasSensorIcon,
  },
  BoostPressureMonitor: {
    name: "Boost Pressure Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} Boost Pressure Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} Boost Pressure Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: BarometricPressureIcon,
  },
  NOxSCRMonitor: {
    name: "NOx/SCR Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} NOx/SCR Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} NOx/SCR Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: NOxSCRMonitorIcon,
  },
  NMHCCatalyst: {
    name: "NMHC Catalyst Monitor",
    description: "Receive alerts when the monitor changes to PASS or FAIL.",
    triggerType: "value",
    triggerValue: false,
    triggerStatusTexts: ["PASS", "FAIL"],
    textTemplates: {
      shop: "Alert: {customerName} NMHC Catalyst Monitor has changed to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} NMHC Catalyst Monitor has changed to a status of {value} in {vehicleName}. Please call us to discuss the issue.",
    },
    icon: CatalystMonitorIcon,
  },
  OxygenSensor: {
    name: "Oxygen Sensor",
    triggerType: "range",
    triggerRangeImperial: [0, 1.275],
    triggerRangeMetric: [0, 1.275],
    measurementUnits: ["Volts", "Volts"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has an Oxygen Sensor of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Oxygen Sensor in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: OxygenSensorMonitorIcon,
  },
  FuelTrimLong: {
    name: "Fuel Trim Long Term",
    triggerType: "range",
    triggerRangeImperial: [-100, 100],
    triggerRangeMetric: [-100, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Fuel Trim Long Term of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Fuel Trim Long Term in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: FuelSystemMonitorIcon,
  },
  FuelTrimShort: {
    name: "Fuel Trim Short Term",
    triggerType: "range",
    triggerRangeImperial: [-100, 100],
    triggerRangeMetric: [-100, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Fuel Trim Short Term of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Fuel Trim Short Term in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: FuelSystemMonitorIcon,
  },
  IgnitionOn: {
    name: "Ignition On",
    description: "Receive alerts when the ignition state changes to ON or OFF.",
    triggerType: "value",
    triggerValue: true,
    triggerStatusTexts: ["ON", "OFF"],
    textTemplates: {
      shop: "Alert: {customerName} has ignition state change to a status of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that ignition in {vehicleName} has changed to a status of {value}.",
    },
    icon: IgnitionIcon,
  },
  EngineOilLife: {
    name: "Engine Oil Life",
    triggerType: "range",
    triggerRangeImperial: [0, 100],
    triggerRangeMetric: [0, 100],
    measurementUnits: ["%", "%"],
    convertMetricToImperial: alertsConvertMethods.doNotConvert,
    convertImperialToMetric: alertsConvertMethods.doNotConvert,
    textTemplates: {
      shop: "Alert: {customerName} has a Engine Oil Life of {value} in {vehicleName}.",
      customer:
        "Hi, this is {shopName} we have detected that the Engine Oil Life in {vehicleName} is {value}. Please call us to discuss the issue.",
    },
    icon: EngineOilLifeIcon,
  },
};

export const TOOLTIP_CLOSING_TIME = 10000;

export const SCREEN_MOBILE = 768;
export const SCREEN_TABLET = 1024;
export const SCREEN_LARGE = 1200;
export const SCREEN_EXTRA_LARGE = 1600;
export const SCREEN_VIEW_SIZES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  MIDDLE_PC: "middlePC",
  PERSONAL_COMPUTER: "personalComputer",
  LARGE_PERSONAL_COMPUTER: "largePersonalComputer",
};

export const TABLE_COLUMN_ID = {
  CUSTOMER: "customer",
  SHOP_LOCATION: "shopLocation",
  VEHICLES: "vehicles",
  VEHICLES_ENGINE_LIGHTS: "vehiclesEngineLights",
  VEHICLES_WARNINGS: "vehiclesWarnings",
  VEHICLE_DTC_CODES: "vehiclesDTCCodes",
  VEHICLE_OTHER_ALERTS: "vehiclesOtherAlerts",
};

export const TABLE_USERS_COLUMN_ID = {
  USER_NAME: "userName",
  USER_STEER_NAME: "userSteerName",
  USER_CONTROLS: "userControls",
  USER_DROPDOWN: "userDropDown",
};

export const PATHNAMES = {
  CUSTOMERS: "customers",
  DEVICE_INVENTORY: "device-inventory",
  VEHICLES: "vehicles",
  DASHBOARD: "dashboard",
};

export const ALL_LOCATIONS = {
  id: "tenant",
  name: "Tenant Dashboard",
  streetAddress: "All Locations",
};

export const ORDER_DEVICES_STEP = {
  ORDER_DEVICES: 1,
  DELIVERY_INFO: 2,
  SUMMARY: 3,
};
