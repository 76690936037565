import React from "react";
import cn from "classnames";

import FlagsIcon from "../../../../../assets/images/flags-for-phone-input.png";
import styles from "./CountryFlag.module.scss";

const CountryFlag = ({ countryIso2 }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${FlagsIcon})`,
      }}
      className={cn(styles.CountryFlag, "border-radius-circle", [
        styles[`CountryFlag__${countryIso2.toLowerCase()}`],
      ])}
    />
  );
};

export default CountryFlag;
