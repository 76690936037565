import React, { useRef, useEffect } from "react";
import cn from "classnames";
import onClickOutside from "react-onclickoutside";

import CountryFlag from "../CountryFlag/CountryFlag";
import styles from "./CountriesList.module.scss";

function CountriesList({
  countriesList,
  onSelectCountry,
  selectedCountry,
  onOutsideClick,
}) {
  const containerRef = useRef(null);
  const selectedCountryRef = useRef(null);

  CountriesList.handleClickOutside = () => {
    onOutsideClick(false);
  };

  useEffect(() => {
    const containerTop =
      containerRef?.current?.getBoundingClientRect()?.top || 0;
    const selectedCountryTop =
      selectedCountryRef?.current?.getBoundingClientRect()?.top || 0;
    const distanceToSelectedCountry = selectedCountryTop - containerTop;

    containerRef.current.scrollTop = distanceToSelectedCountry - 81;
  }, []);

  return (
    <ul
      ref={containerRef}
      className={cn(
        styles.CountriesList,
        "w-full absolute border-radius-7 box-shadow-dark"
      )}
    >
      {countriesList.map(({ name, iso2, countryCode }) => {
        return (
          <li
            ref={selectedCountry === iso2 ? selectedCountryRef : null}
            key={iso2}
            className={cn(
              styles.CountriesList__country,
              "flex items-center font-md py-14 px-20",
              {
                [styles.isSelected]: selectedCountry === iso2,
              }
            )}
            onClick={() => onSelectCountry(iso2)}
          >
            <CountryFlag countryIso2={iso2} />
            <span
              className={cn(
                styles.CountriesList__countryName,
                "font-bold text-color-secondary mx-8"
              )}
            >
              {name}
            </span>
            <span className="text-color-quaternary">+{countryCode}</span>
          </li>
        );
      })}
    </ul>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => CountriesList.handleClickOutside,
};

export default onClickOutside(CountriesList, clickOutsideConfig);
