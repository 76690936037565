import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import examples from "libphonenumber-js/examples.mobile.json";
import {
  getCountryCallingCode,
  getExampleNumber,
  AsYouType,
  getPhoneCode,
} from "libphonenumber-js";

import CountryFlag from "./components/CountryFlag/CountryFlag.js";
import CountriesList from "./components/CountriesList/CountriesList.js";
import {
  parseNumberData,
  generateRawCountries,
  formatPhoneNumberToNational,
  getPhoneWithCountryCode,
  keepCursor,
} from "./PhoneInput.utils.js";
import { ArrowDownIcon } from "../../../assets/icons/index.js";
import styles from "./PhoneInput.module.scss";
import countriesName from "./countriesName.js";

const PhoneInput = ({
  value: propsValue = "",
  name,
  onChange,
  onBlur,
  onFocus,
  defaultCountry = "US",
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [value, setValue] = useState("");
  const phoneInput = useRef();
  useEffect(() => {
    // allow to reset value from parent component;
    if (!propsValue) {
      setValue("");
      return;
    }

    const tempNumber =
      typeof propsValue === "number" ? propsValue.toString() : propsValue;
    // parseNumberData() can't parse number with length less than 3
    if (tempNumber && tempNumber.length > 3) {
      const phoneWithPlus = getPhoneWithCountryCode(
        tempNumber,
        selectedCountry
      );
      const { phoneNumber } = parseNumberData(phoneWithPlus);
      const formattedPhoneNumber = formatPhoneNumberToNational(
        phoneNumber,
        selectedCountry
      );

      if (formattedPhoneNumber !== value) {
        setValue(formattedPhoneNumber);
        keepCursor(phoneInput.current, formattedPhoneNumber);
      }
    }
  }, [propsValue]);

  useEffect(() => {
    const tempNumber =
      typeof propsValue === "number" ? propsValue.toString() : propsValue;
    // parseNumberData() can't parse number with length less than 3
    if (tempNumber && tempNumber.length > 3) {
      const phoneWithPlus = getPhoneWithCountryCode(
        tempNumber,
        selectedCountry
      );
      const { country } = parseNumberData(phoneWithPlus);

      setSelectedCountry(country || defaultCountry);
    }
  }, []);

  function onInputChange(event) {
    const { value } = event.target;

    if (!value) {
      onChange && onChange(value);
      setValue(value);
      return;
    }

    if (value && !value?.match(/^[\d() -]+$/g)) {
      return;
    }

    const formattedIncompleteValue = formatPhoneNumberToNational(
      value,
      selectedCountry
    );

    const formattedValue = `+${getPhoneCode(
      selectedCountry
    )}${formattedIncompleteValue}`;

    const asYouType = new AsYouType();
    asYouType.input(formattedValue);

    const plainNumber = asYouType.getNumber()?.number;

    if (plainNumber?.length >= 13) return;

    setValue(formattedIncompleteValue);
    onChange && onChange(plainNumber);

    keepCursor(phoneInput.current, formattedIncompleteValue);
  }

  function onSelectCountry(iso2) {
    setSelectedCountry(iso2);
    setValue("");
    setIsOpen(false);
  }

  const reorderedCountries = generateRawCountries(countriesName);

  return (
    <div
      className={cn(
        styles.PhoneInputContiner,
        "relative flex border-radius-5",
        className
      )}
    >
      <div
        className={cn(
          styles.PhoneInputButton,
          "flex items-center pl-20 pr-12",
          "countries-list-toggle"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <CountryFlag countryIso2={selectedCountry} />
        <div className="font-bold font-md text-color-secondary ml-8 mr-4">
          +{getCountryCallingCode(selectedCountry)}
        </div>
        <div
          className={cn(
            styles.PhoneInputButton__arrow,
            "flex items-center justify-center",
            {
              [styles.isArrowRotate]: isOpen,
            }
          )}
        >
          <ArrowDownIcon />
        </div>
      </div>
      <input
        ref={phoneInput}
        className={cn(
          styles.PhoneInput,
          "px-10 font-bold font-md text-color-secondary pt-2"
        )}
        placeholder={getExampleNumber(
          selectedCountry,
          examples
        ).formatNational()}
        value={value}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onInputChange}
      />
      {isOpen && (
        <CountriesList
          countriesList={reorderedCountries}
          onSelectCountry={onSelectCountry}
          selectedCountry={selectedCountry}
          onOutsideClick={() => setIsOpen(false)}
          outsideClickIgnoreClass="countries-list-toggle"
        />
      )}
    </div>
  );
};

export default PhoneInput;
