import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import {
  logoutUser,
  getUserFirstName,
  getUserLastName,
} from "../../../App/ui.slice";
import styles from "./UserDropdown.module.scss";
import { ArrowDownIcon, LogoutIcon } from "../../../assets/icons";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

export default function UserDropdown({ isMenuOpen }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userName = userFirstName + " " + userLastName;

  const handleToggle = () => setIsOpen((x) => !x);
  const handleLogout = () => dispatch(logoutUser());

  return (
    <div className={styles.wrapper}>
      <Dropdown isOpen={isOpen} toggle={handleToggle}>
        <DropdownToggle
          tag="div"
          className={cn(styles.button, {
            [styles.detailed]: isMenuOpen,
            [styles.active]: isOpen,
          })}
        >
          <div className={styles.avatar}></div>
          <div className={styles.name}>{userName}</div>
          <ArrowDownIcon className={styles.arrow} />
        </DropdownToggle>
        <DropdownMenu className={styles.menu}>
          <DropdownItem className={styles.item} onClick={handleLogout}>
            <LogoutIcon className={styles.icon} />
            <div className={styles.title}>Log Out</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
