import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useLayoutEffect,
  createRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { debounce } from "lodash";
import cn from "classnames";

import styles from "./Filters.module.scss";
import stylesRequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";

import { getVehiclesFilters, updateVehiclesFilters } from "../../App/ui.slice";
import { getFiltersData } from "../customers/customers.selectors";

import {
  FiltersIcon,
  CloseIcon,
  ArrowBlueIcon,
  NoResultIcon,
  ArrowDownIcon,
} from "../../assets/icons";

import { Modal } from "reactstrap";

import Switch from "../../components/ui/switch/Switch";
import RangeInput from "../../components/ui/range-input/RangeInput";
import rangeInputStyles from "../../components/ui/range-input/styles.module.scss";
import SearchField from "../shops/HeaderShopToggle/components/SearchField/SearchField";
import RequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import { getFilteredCustomers } from "../customers/customers.selectors";
import Checkbox from "../../components/ui/checkbox/Checkbox";

const FILTERS_CONFIG = {
  serviceMiles: {
    title: "Miles Since Last Service",
    measureUnit: "miles",
    component: FilterRange,
    minValue: 0,
    maxValue: 100000,
    getValue: ([min, max]) => [`${min} - ${max}`],
  },
  serviceDays: {
    title: "Days Since Last Service",
    measureUnit: "days",
    component: FilterRange,
    minValue: 0,
    maxValue: 365,
    getValue: ([min, max]) => [`${min} - ${max}`],
  },
  warnings: {
    title: "Vehicle Has a Warning Light ON",
    component: FilterBoolean,
    getValue: (data) => (data ? ["Yes"] : ["No"]),
  },
  others: {
    title: "Vehicle Has Other Alert ON",
    component: FilterBoolean,
    getValue: (data) => (data ? ["Yes"] : ["No"]),
  },
  checkEngine: {
    title: "Vehicle Has a Check Engine ON",
    component: FilterBoolean,
    getValue: (data) => (data ? ["Yes"] : ["No"]),
  },
  psi: {
    title: "Lowest PSI",
    measureUnit: "PSI",
    component: FilterRange,
    minValue: 0,
    maxValue: 100,
    getValue: ([min, max]) => [`${min} - ${max}`],
  },
  dtcKeys: {
    title: "DTC Code Search",
    component: FilterTagsInput,
    getValue: (data) => data,
  },
  serviceNames: {
    title: "Service Type Search",
    component: FilterTagsInput,
    getValue: (data) => data,
  },
  vehicles: {
    title: "Search Vehicles by",
    component: FilterTagsDropdowns,
    getValue: (data) => Object.values(data).flat(),
  },
  customers: {
    title: "Customers",
    component: FilterCheckboxes,
    getValue: (data) => data,
  },
};

export default function Filters() {
  const dispatch = useDispatch();
  const filters = useSelector(getVehiclesFilters);
  const filtersData = useSelector(getFiltersData);
  const [searchString, setSearchString] = useState("");
  const [displayedFilters, setDisplayedFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState({});
  const refSummaryFilters = useRef(null);
  const [isSummaryMultiRow, setIsSummaryMultiRow] = useState(false);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);

  useEffect(() => {
    const search = searchString.toLocaleLowerCase().trim();

    const filtersToSet = Object.keys(filters).reduce((acc, id) => {
      const title = FILTERS_CONFIG[id].title.toLowerCase();
      if (title.includes(search)) acc[id] = filters[id];
      return acc;
    }, {});

    setDisplayedFilters(filtersToSet);
  }, [filters, searchString]);

  useEffect(() => {
    if (refSummaryFilters.current && !isSummaryExpanded) {
      const isMultiRow =
        refSummaryFilters.current.scrollWidth >
        refSummaryFilters.current.clientWidth;
      setIsSummaryMultiRow(isMultiRow);
    }
  }, [filters, update]);

  const summaryFilters = Object.entries({ ...filters, ...update }).reduce(
    (acc, [key, filter]) => {
      if (filter.isActive) {
        acc[key] = FILTERS_CONFIG[key].getValue(filter.data);
      }

      return acc;
    },
    {}
  );

  const counter = Object.values(filters).filter((x) => x.isActive).length;
  const counterUpdate = Object.values({ ...filters, ...update }).filter(
    (x) => x.isActive
  ).length;

  const handleToggle = () => {
    setIsOpen((x) => !x);
    setUpdate({});
    setSearchString("");
    setIsSummaryExpanded(false);
  };
  const handleSearchChange = useCallback(
    debounce((value) => {
      setSearchString(value);
    }, 300),
    []
  );
  const handleFilterChange = (value) => setUpdate((x) => ({ ...x, ...value }));
  const handleFilterClear = (id, i, value) => {
    const filterUpdate = { ...filters[id], ...update[id] };

    if (Array.isArray(filterUpdate.data)) {
      if (filterUpdate.data.length > 1) {
        filterUpdate.data = filterUpdate.data.toSpliced(i, 1);
      } else {
        filterUpdate.data = [];
        filterUpdate.isActive = false;
      }
    } else if (typeof filterUpdate.data === "object") {
      filterUpdate.data = { ...filterUpdate.data };
      let tagsCount = 0;
      for (const key in filterUpdate.data) {
        const idx = filterUpdate.data[key].findIndex((el) => el === value);
        if (idx >= 0) {
          if (filterUpdate.data[key].length > 1) {
            filterUpdate.data[key] = filterUpdate.data[key].toSpliced(idx, 1);
          } else {
            filterUpdate.data[key] = [];
          }
        }
        tagsCount += filterUpdate.data[key].length;
      }
      if (tagsCount === 0) filterUpdate.isActive = false;
    } else {
      filterUpdate.isActive = false;
    }

    setUpdate((x) => ({ ...x, [id]: filterUpdate }));
  };
  const handleFiltersClear = () => {
    const updateToSet = Object.entries({ ...filters, ...update }).reduce(
      (acc, [id, value]) => {
        if (value.isActive) acc[id] = { ...value, isActive: false };
        return acc;
      },
      {}
    );
    setUpdate((x) => ({ ...x, ...updateToSet }));
  };
  const handleFiltersUpdate = () => {
    if (Object.keys(update).length) {
      dispatch(updateVehiclesFilters(update));
      handleToggle();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.button} onClick={handleToggle}>
        <FiltersIcon />
        <div className={styles.title}>Filters</div>
        {counter ? <div className={styles.counter}>{counter}</div> : null}
      </div>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        className={styles.modal}
        contentClassName={styles.content}
      >
        <div className={styles.summary}>
          <div className={styles.header}>
            <div className={styles.title}>Filters</div>
            {counterUpdate ? (
              <div className={styles.counter}>{counterUpdate}</div>
            ) : null}
            <CloseIcon className={styles.closeButton} onClick={handleToggle} />
          </div>
          <SearchField
            className={styles.search}
            placeholder="Search..."
            onChange={handleSearchChange}
          />
          {counterUpdate ? (
            <div
              className={cn(styles.activeFilters, {
                [styles.expanded]: isSummaryExpanded,
              })}
              ref={refSummaryFilters}
            >
              <div className={styles.clearButton} onClick={handleFiltersClear}>
                <CloseIcon />
              </div>
              {Object.entries(summaryFilters).map(([key, values]) => (
                <div className={styles.filter}>
                  <div className={styles.title}>
                    {FILTERS_CONFIG[key].title}:
                  </div>
                  {values.map((value, i) => (
                    <div className={styles.value} key={key + i}>
                      {value}
                      <CloseIcon
                        onClick={() => handleFilterClear(key, i, value)}
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div
                className={cn(styles.moreButton, {
                  [styles.hidden]: isSummaryExpanded || !isSummaryMultiRow,
                })}
              >
                <div
                  className={styles.clickArea}
                  onClick={() => setIsSummaryExpanded(true)}
                >
                  More
                  <ArrowBlueIcon className={styles.icon} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {Object.keys(displayedFilters).length ? (
          <>
            <div className={styles.container}>
              {Object.keys(displayedFilters).map((id) => {
                const Component = FILTERS_CONFIG[id].component;
                return (
                  <Component
                    id={id}
                    state={update[id] || displayedFilters[id]}
                    onChange={handleFilterChange}
                  />
                );
              })}
            </div>
            <div
              className={cn(styles.saveButton, {
                [styles.disabled]: !Object.keys(update).length,
              })}
              onClick={handleFiltersUpdate}
            >
              Show Results
            </div>
          </>
        ) : (
          <RequestResult
            wrapperClassName={stylesRequestResult.noSearchDataFiltersWrapper}
            className={stylesRequestResult.noSearchData}
            image={<NoResultIcon width={160} height={160} />}
            title="No Results Found"
            message={
              "We couldn't find anything matching your search.\nPlease try again."
            }
          />
        )}
      </Modal>
    </div>
  );
}

function FilterBoolean({ id, state, onChange }) {
  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (value) => {
    onChange({ [id]: { ...state, data: value } });
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <div className={styles.selectorBoolean}>
        <div
          className={cn(styles.item, { [styles.active]: state.data })}
          onClick={() => handleChange(true)}
        >
          Yes
        </div>
        <div
          className={cn(styles.item, { [styles.active]: !state.data })}
          onClick={() => handleChange(false)}
        >
          No
        </div>
      </div>
    </div>
  );
}

function FilterRange({ id, state, onChange }) {
  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (value) => {
    onChange({ [id]: { ...state, data: value } });
  };

  return (
    <div className={styles.card} key={id}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <RangeInput
        className={rangeInputStyles.filters}
        label={`Value range (${FILTERS_CONFIG[id].measureUnit})`}
        minValue={FILTERS_CONFIG[id].minValue}
        maxValue={FILTERS_CONFIG[id].maxValue}
        step={1}
        value={{ min: state.data[0], max: state.data[1] }}
        withInputs
        onChange={({ min, max }) => handleChange([min, max])}
        onSliderFinalChange={() => {}}
        onInputFinalChange={() => {}}
      />
    </div>
  );
}

function FilterTagsInput({ id, state, onChange }) {
  const [value, setValue] = useState("");
  const refInput = useRef();

  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (value) => {
    onChange({ [id]: { ...state, data: value } });
  };
  const handleAddTag = (tag) => {
    handleChange([...state.data, tag]);
    setValue("");
  };
  const handleKeyDown = (e) => {
    if ([13, 32, 186, 188].includes(e.keyCode)) {
      e.preventDefault();
      if (value) handleAddTag(value);
    } else if (8 === e.keyCode && !value) {
      e.preventDefault();
      handleChange(state.data.slice(0, -1));
    }
  };

  return (
    <div className={styles.card} key={id}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <div
        className={styles.tagsInput}
        onClick={() => refInput.current.focus()}
      >
        <div className={styles.tagsContainer}>
          {state.data.map((tag, i) => (
            <div className={styles.tag} key={i}>
              {tag}
              <CloseIcon
                onClick={() => handleChange(state.data.toSpliced(i, 1))}
              />
            </div>
          ))}
          <div className={styles.inputWrapper}>
            {value}
            <input
              className={styles.input}
              ref={refInput}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={handleKeyDown}
            ></input>
          </div>
        </div>
        <div
          className={cn(styles.clearButton, {
            [styles.hidden]: !state.data.length && !value,
          })}
          onClick={() => {
            handleChange([]);
            setValue("");
          }}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
}

function FilterTagsDropdowns({ id, state, onChange }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState({});
  const [searchValues, setSearchValues] = useState({});
  const [inputsState, setInputsState] = useState({});

  const filtersData = useSelector(getFiltersData);

  const refs = useMemo(
    () =>
      Object.keys(state.data).reduce((acc, key) => {
        acc[key] = createRef();
        return acc;
      }, {}),
    [Object.keys(state.data).join("")]
  );

  useLayoutEffect(() => {
    for (const key in refs) {
      const isExpandable = refs[key].current.scrollHeight > 30;

      setInputsState((prev) => ({
        ...prev,
        [key]: { ...prev[key], isExpandable },
      }));
    }
  }, [refs, state.data]);

  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };
  const handleChange = (key, value, e) => {
    if (e) e.stopPropagation();
    onChange({ [id]: { ...state, data: { ...state.data, [key]: value } } });
  };

  const handleToggleInputExpanded = (key, e) => {
    e.stopPropagation();
    setInputsState((prev) => ({
      ...prev,
      [key]: { ...prev[key], isExpanded: !prev[key].isExpanded },
    }));
  };

  const handleToggleDropdown = (key) => {
    setIsDropdownOpen((x) => ({ ...x, [key]: !x[key] }));
  };

  const handleSearchChange = useCallback(
    debounce((key, value) => {
      setSearchValues((prev) => ({
        ...prev,
        [key]: value ? value.toLowerCase() : "",
      }));
    }, 300),
    []
  );

  return (
    <div className={styles.card} key={id}>
      <div className={styles.header}>
        <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      {Object.entries(state.data).map(([key, tags]) => (
        <div key={key} className={styles.tagsDropdown}>
          <div className={styles.label}>{key}</div>
          <div
            className={styles.tagsInput}
            onClick={() => handleToggleDropdown(key)}
          >
            <div
              className={cn(styles.tagsContainer, {
                [styles.expanded]: inputsState[key]?.isExpanded,
              })}
              ref={refs[key]}
            >
              {tags.map((tag, i) => (
                <div className={styles.tag} key={i}>
                  {tag}
                  <CloseIcon
                    onClick={(e) => handleChange(key, tags.toSpliced(i, 1), e)}
                  />
                </div>
              ))}
            </div>
            {inputsState[key]?.isExpandable ? (
              <div
                className={styles.expandButton}
                onClick={(e) => handleToggleInputExpanded(key, e)}
              >
                ...
              </div>
            ) : null}
            {tags.length ? (
              <div className={styles.counter}>{tags.length}</div>
            ) : null}
            <div
              className={cn(styles.clearButton, {
                [styles.hidden]: !state.data[key].length,
              })}
              onClick={(e) => handleChange(key, [], e)}
            >
              <CloseIcon />
            </div>
            <div
              className={cn(styles.dropdownButton, {
                [styles.active]: isDropdownOpen[key],
              })}
            >
              <ArrowDownIcon />
            </div>
          </div>
          <div
            className={cn(styles.dropdown, {
              [styles.hidden]: !isDropdownOpen[key],
            })}
          >
            <div className={styles.header}>
              <div className={styles.title}>{key}</div>
              <div className={styles.counterDropdown}>
                {`(${tags.length}/${filtersData[id][key].length})`}
              </div>
              <div
                className={styles.button}
                onClick={() =>
                  handleChange(
                    key,
                    tags.length === filtersData[id][key].length
                      ? []
                      : filtersData[id][key]
                  )
                }
              >
                {tags.length === filtersData[id][key].length
                  ? "Unselect All"
                  : "Select All"}
              </div>
              <SearchField
                className={styles.search}
                placeholder={`Search for ${key}...`}
                onChange={(val) => handleSearchChange(key, val)}
              />
            </div>
            <div className={styles.items}>
              {tags.map((tag, i) =>
                tag
                  .toString()
                  .toLowerCase()
                  .includes(searchValues[key] || "") ? (
                  <div
                    key={i}
                    className={styles.item}
                    onClick={() => handleChange(key, tags.toSpliced(i, 1))}
                  >
                    <Checkbox
                      isChecked={true}
                      title={tag}
                      className={styles.checkbox}
                    />
                  </div>
                ) : null
              )}
              {filtersData[id][key].map((tag, i) =>
                tags.includes(tag) ? null : tag
                    .toString()
                    .toLowerCase()
                    .includes(searchValues[key] || "") ? (
                  <div
                    key={i}
                    className={styles.item}
                    onClick={() => handleChange(key, [...tags, tag])}
                  >
                    <Checkbox
                      isChecked={false}
                      title={tag}
                      className={styles.checkbox}
                    />
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FilterCheckboxes({ id, state, onChange }) {
  const customersList = useSelector(getFilteredCustomers);
  const [customersTogglers, setCustomersTogglers] = useState({});
  const [allCustomersToggled, setAllCustomersToggled] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const uniqueCustomers = useMemo(() => {
    const seenNames = new Set();
    return customersList.filter((customer) => {
      if (!seenNames.has(customer.name)) {
        seenNames.add(customer.name);
        return true;
      }
      return false;
    });
  }, [customersList]);

  const customerMap = useMemo(() => {
    return uniqueCustomers.reduce((acc, customer) => {
      acc[customer.id] = customer.name;
      return acc;
    }, {});
  }, [uniqueCustomers]);

  const onSearchChange = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 300),
    []
  );

  const filteredCustomers = useMemo(() => {
    if (!searchValue) return uniqueCustomers;
    return uniqueCustomers.filter((customer) =>
      customer.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, uniqueCustomers]);

  useEffect(() => {
    const newTogglers = uniqueCustomers.reduce((acc, customer) => {
      acc[customer.id] =
        state.data?.includes(customerMap[customer.id]) || false;
      return acc;
    }, {});
    setCustomersTogglers(newTogglers);
  }, [state.data]);

  useEffect(() => {
    setAllCustomersToggled(Object.values(customersTogglers).every(Boolean));
  }, [customersTogglers]);

  const handleChange = (updatedTogglers) => {
    const selectedNames = Object.entries(updatedTogglers)
      .filter(([_, isChecked]) => isChecked)
      .map(([customerId]) => customerMap[customerId]);

    onChange({
      [id]: {
        ...state,
        data: selectedNames,
      },
    });
  };

  const toggleAll = () => {
    const newState = Object.fromEntries(
      Object.keys(customersTogglers).map((customerId) => [
        customerId,
        !allCustomersToggled,
      ])
    );

    setCustomersTogglers(newState);
    setAllCustomersToggled(!allCustomersToggled);
    handleChange(newState);
  };

  const toggleCustomer = (customerId) => {
    setCustomersTogglers((prev) => {
      const newState = { ...prev, [customerId]: !prev[customerId] };
      handleChange(newState);
      return newState;
    });
  };

  const handleToggle = () => {
    onChange({ [id]: { ...state, isActive: !state.isActive } });
  };

  return (
    <div className={styles.card} key={id}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{FILTERS_CONFIG[id].title}</div>
          <div className={styles.checkboxesCounter}>
            ({Object.values(customersTogglers).filter(Boolean).length}/
            {uniqueCustomers.length})
          </div>
          <div className={styles.delimiter} />
          <div className={styles.selectAllButton} onClick={toggleAll}>
            {allCustomersToggled ? "Unselect All" : "Select All"}
          </div>
        </div>
        <Switch
          cls={styles.toggler}
          isChecked={state.isActive}
          onChange={handleToggle}
        />
      </div>
      <SearchField
        className={styles.search}
        placeholder="Search for customer..."
        onChange={onSearchChange}
      />
      <div className={styles.checkboxContainer}>
        {filteredCustomers.map((customer) => (
          <div key={customer.id}>
            <Checkbox
              isChecked={customersTogglers[customer.id] || false}
              title={customer.name}
              onChange={() => toggleCustomer(customer.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
