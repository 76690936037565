import React from "react";
import cn from "classnames";
import styles from "./Checkbox.module.scss";

export default function Checkbox({ isChecked, onChange, title, className }) {
  const handleClick = () => {
    if (!onChange) return;
    onChange(!isChecked);
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.selected]: isChecked,
        [className]: !!className,
      })}
      onClick={handleClick}
    >
      <div className={styles.checkbox} />
      <div className={styles.title}>{title}</div>
    </div>
  );
}
