import { createSelector } from "@reduxjs/toolkit";

export const isLoaded = (state) => state.shops.byId;

export const getShops = (state) =>
  state.shops.byId && Object.values(state.shops.byId);
export const getShopsById = (state) => state.shops.byId;

export const getSelectedShop = (state) => state.shops.selectedShop;
export const getShopFilter = (state) => state.shops.shopFilter;
export const getIsShopsError = (state) => state.shops.hasError;

export const getSelectedShopId = createSelector(
  getSelectedShop,
  (selectedShop) => (selectedShop ? selectedShop.id : null)
);

export const getFilteredShops = createSelector(
  [getShops, getShopFilter],
  (shops, filterValue) => {
    return filterValue
      ? shops.filter(
          ({ name }) =>
            name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
        )
      : shops;
  }
);

export const getShopDevices = (state) => state.shops.shopDevices;
export const isShopDevicesLoading = (state) => state.shops.isShopDevicesLoading;
export const getDeviceFilter = (state) => state.shops.deviceFilter;

export const getFilteredDevices = createSelector(
  [getShopDevices, getDeviceFilter],
  (shopDevices, filterValue) => {
    return filterValue
      ? shopDevices.filter(
          ({ deviceSerialNumber }) =>
            deviceSerialNumber
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) !== -1
        )
      : shopDevices;
  }
);

export const isOrderDevicesModalOpen = (state) =>
  state.shops.isOrderDevicesModalOpen;

export const getCurrentStep = (state) => state.shops.currentStep;

export const getIsOrderDevicesError = (state) => state.shops.isOrderError;
export const getIsOrderDevicesLoading = (state) => state.shops.isOrderLoading;
