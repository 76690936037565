import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import { WARNINGS, PREDICTION_WARNINGS } from "../../constants";

import {
  fetchCustomers as fetchCustomersRequest,
  createCustomer as createCustomerRequest,
  editCustomer as editCustomerRequest,
  deleteCustomer as deleteCustomerRequest,
  fetchSteerCustomers as fetchSteerCustomersRequest,
  fetchTenantCustomers as fetchTenantCustomersRequest,
} from "../../services/api.service";

import {
  getCustomers,
  getIdsByVins,
  getCustomersBySteerId,
} from "./customers.selectors";

const fetchCustomersThunk = createAsyncThunk("customers/fetch", (params) =>
  fetchCustomersRequest(params)
);

const fetchTenantCustomersThunk = createAsyncThunk(
  "customers/fetchTenantCustomers",
  async (data) => {
    const tenantCustomers = await fetchTenantCustomersRequest(data);
    return tenantCustomers;
  }
);

const createCustomerThunk = createAsyncThunk(
  "customers/createCustomer",
  async (data, thunkAPI) => {
    try {
      await createCustomerRequest(data);
      thunkAPI.dispatch(fetchCustomersThunk());
    } catch (e) {
      await thunkAPI.dispatch(setCustomerError(e));
    }
  }
);
const editCustomerThunk = createAsyncThunk(
  "customers/editCustomer",
  async (data, thunkAPI) => {
    await editCustomerRequest(data);
    thunkAPI.dispatch(fetchCustomersThunk());
  }
);

const deleteCustomerThunk = createAsyncThunk(
  "customers/deleteCustomer",
  deleteCustomerRequest
);

const fetchSteerCustomersThunk = createAsyncThunk(
  "customers/SteerAll",
  async (data, thunkAPI) => {
    const steerCustomers = await fetchSteerCustomersRequest(data);
    const addedCustomers = getCustomersBySteerId(thunkAPI.getState());
    return { steerCustomers, addedCustomers };
  }
);

const searchCustomersThunk = createAsyncThunk(
  "customers/search",
  async (searchString, thunkAPI) => {
    if (!searchString || searchString.length < 3) return null;

    const customers = getCustomers(thunkAPI.getState());
    const idsByVins = getIdsByVins(thunkAPI.getState());

    const matches = {};
    const matchProperties = ["name", "email", "phoneNumber"];

    for (const customer of customers) {
      for (const property of matchProperties) {
        if (customer[property]) {
          const index = customer[property]
            .toLowerCase()
            .search(searchString.toLowerCase());
          if (index !== -1) {
            matches[customer.id] = {
              property,
              index,
              length: searchString.length,
            };
            break;
          }
        }
      }
    }

    for (const [vin, { customerId, vehicleId }] of Object.entries(idsByVins)) {
      const vinIndex = vin.toLowerCase().search(searchString.toLowerCase());
      if (vinIndex !== -1) {
        matches[customerId] = {
          ...matches[customerId],
          vehicleId,
          vinIndex,
          length: searchString.length,
        };
      }
    }

    return matches;
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    byId: null,
    bySteerId: {},
    byVin: {},
    isLoading: false,
    isRemoveModalShown: false,
    isRemovingCustomer: false,
    hasError: false,
    selectedCustomer: null,
    customerFilter: "",
    steerCustomers: null,
    steerNotAddedCustomers: null,
    isSteerLoading: false,
    error: "",
    customersSearchResult: null,
    isCustomersSearchLoading: false,
    filtersData: {},
  },
  reducers: {
    selectCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    setCustomerFilter(state, action) {
      state.customerFilter = action.payload;
    },
    selectVehicle(state, action) {
      state.selectedVehicle = action.payload;
    },
    setVehicleFilter(state, action) {
      state.vehicleFilter = action.payload;
    },
    setCustomerVehicleListLoading(state, action) {
      const customerId = action.payload;

      state.byId[customerId].hasVehicleListError = false;
      state.byId[customerId].isVehiclesListLoading = true;
    },
    setCustomerVehicleListLoadingError(state, action) {
      const customerId = action.payload;
      state.byId[customerId].hasVehicleListError = true;
      state.byId[customerId].isVehiclesListLoading = false;
    },
    setCustomerVehicleListLoadingSuccess(state, action) {
      const customerId = action.payload;
      state.byId[customerId].hasVehicleListError = false;
      state.byId[customerId].isVehiclesListLoading = false;
    },
    setCustomerError(state, action) {
      state.error = action.payload;
    },
    setRemoveModalShown(state, action) {
      state.isRemoveModalShown = action.payload;
    },
  },
  extraReducers: {
    [fetchCustomersThunk.pending](state, action) {
      const { id } = action.meta.arg || {};

      if (!id) state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchCustomersThunk.fulfilled](state, action) {
      const { id } = action.meta.arg || {};

      state.isLoading = false;
      state.hasError = false;
      state.error = "";

      const customersList = [];
      const vins = {};
      const filterDataVehicles = {
        years: new Set(),
        makes: new Set(),
        models: new Set(),
      };

      action.payload.customers.forEach((customer) => {
        customersList.push(convertToCustomerModel(customer));
        Object.entries(customer.vehicles).forEach(
          ([vehicleId, { vin, year, make, model }]) => {
            if (vin) vins[vin] = { customerId: customer._id, vehicleId };
            if (year) filterDataVehicles.years.add(year);
            if (make) filterDataVehicles.makes.add(make);
            if (model) filterDataVehicles.models.add(model);
          }
        );
      });

      state.filtersData.vehicles = {
        year: Array.from(filterDataVehicles.years).sort(),
        make: Array.from(filterDataVehicles.makes).sort(),
        model: Array.from(filterDataVehicles.models).sort(),
      };

      if (id) {
        const receivedCustomer = customersList[0];
        state.byId[id] = receivedCustomer;
        state.bySteerId[receivedCustomer.steerCustomerId] = id;
        state.byVin = { ...state.byVin, ...vins };
      } else {
        state.byId = keyBy(customersList, "id");
        state.bySteerId = customersList.reduce(
          (acc, { id, steerCustomerId }) => {
            acc[steerCustomerId] = id;
            return acc;
          },
          {}
        );
        state.byVin = vins;
        state.shopProvider = action.payload.shopProvider;
      }
    },
    [fetchCustomersThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [createCustomerThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [createCustomerThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
    },
    [createCustomerThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [editCustomerThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [editCustomerThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
    },
    [editCustomerThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
    },
    [deleteCustomerThunk.pending](state) {
      state.isRemovingCustomer = true;
    },
    [deleteCustomerThunk.fulfilled](state, action) {
      state.isRemovingCustomer = false;
      state.selectedCustomer = null;
      delete state.byId[action.meta.arg.id];
      state.isRemoveModalShown = false;
    },
    [deleteCustomerThunk.rejected](state) {
      state.isRemovingCustomer = false;
      state.isRemoveModalShown = false;
    },
    [fetchSteerCustomersThunk.pending](state) {
      state.steerCustomers = null;
      state.isSteerLoading = true;
    },
    [fetchSteerCustomersThunk.fulfilled](state, action) {
      state.steerCustomers = action.payload.steerCustomers;
      state.steerNotAddedCustomers = action.payload.steerCustomers.filter(
        ({ id }) => !action.payload.addedCustomers[id]
      );
      state.isSteerLoading = false;
    },
    [fetchSteerCustomersThunk.rejected](state) {
      state.steerCustomers = null;
      state.isSteerLoading = false;
    },
    [searchCustomersThunk.pending](state) {
      state.customersSearchResult = null;
      state.isCustomersSearchLoading = true;
    },
    [searchCustomersThunk.fulfilled](state, action) {
      state.customersSearchResult = action.payload;
      state.isCustomersSearchLoading = false;
    },
    [searchCustomersThunk.rejected](state) {
      state.customersSearchResult = {};
      state.isCustomersSearchLoading = false;
    },
    [fetchTenantCustomersThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchTenantCustomersThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.error = "";

      const customersList = [];
      const vins = {};
      const filterDataVehicles = {
        years: new Set(),
        makes: new Set(),
        models: new Set(),
      };

      action.payload.forEach((customer) => {
        customersList.push(convertToCustomerModel(customer));
        Object.entries(customer.vehicles).forEach(
          ([vehicleId, { vin, year, make, model }]) => {
            if (vin) vins[vin] = { customerId: customer._id, vehicleId };
            if (year) filterDataVehicles.years.add(year);
            if (make) filterDataVehicles.makes.add(make);
            if (model) filterDataVehicles.models.add(model);
          }
        );
      });

      state.filtersData.vehicles = {
        year: Array.from(filterDataVehicles.years).sort(),
        make: Array.from(filterDataVehicles.makes).sort(),
        model: Array.from(filterDataVehicles.models).sort(),
      };

      state.byId = keyBy(customersList, "id");
      state.bySteerId = customersList.reduce((acc, { id, steerCustomerId }) => {
        acc[steerCustomerId] = id;
        return acc;
      }, {});
      state.byVin = vins;
      state.shopProvider = action.payload.shopProvider;
    },
    [fetchTenantCustomersThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
  },
});

export { fetchCustomersThunk as fetchCustomers };
export { createCustomerThunk as createCustomer };
export { editCustomerThunk as editCustomer };
export { deleteCustomerThunk as deleteCustomer };
export { fetchSteerCustomersThunk as fetchSteerCustomers };
export { searchCustomersThunk as searchCustomers };
export { fetchTenantCustomersThunk as fetchTenantCustomers };

const { actions, reducer } = customersSlice;
export const {
  selectCustomer,
  setCustomerFilter,
  selectVehicle,
  setVehicleFilter,
  setCustomerVehicleListLoading,
  setCustomerVehicleListLoadingError,
  setCustomerVehicleListLoadingSuccess,
  setCustomerError,
  setRemoveModalShown,
} = actions;
export default reducer;

function convertToCustomerModel({
  _id,
  steerCustomerId,
  name,
  vehicles,
  isBusinessAccount = false,
  notes,
  email,
  phoneNumber,
  additionalPhoneNumbers,
  customerConsented,
  measurementSystem,
  mojioCustomerId,
  shopId,
}) {
  const vehiclesWithConnectedDevices = [];
  const vehiclesWithIgnitionOn = [];
  const vehiclesWithCheckEngineLight = [];
  const vehiclesWithWarnings = [];
  const vehiclesWithDTCCodes = [];
  const vehiclesWithOtherAlerts = [];
  let warningsCount = 0;
  let otherAlertsCount = 0;

  Object.entries(vehicles).forEach(([id, data]) => {
    if (data.connected) vehiclesWithConnectedDevices.push(id);
    if (data.ignition) vehiclesWithIgnitionOn.push(id);
    if (data.checkEngine) vehiclesWithCheckEngineLight.push(id);
    if (data.dtcCount) vehiclesWithDTCCodes.push(id);

    let vehicleWarnings = {};
    let vehicleOtherAlerts = {};
    for (let [warningId, warningData] of Object.entries(data.warnings)) {
      const warningConfig =
        WARNINGS[warningId] || PREDICTION_WARNINGS[warningId];
      if (!warningConfig) continue;
      if (warningConfig.isOtherAlert) {
        vehicleOtherAlerts[warningId] = warningData;
      } else {
        vehicleWarnings[warningId] = warningData;
      }
    }
    const vehicleWarningsCount = Object.keys(vehicleWarnings).length;
    if (vehicleWarningsCount) {
      vehiclesWithWarnings.push(id);
      warningsCount += vehicleWarningsCount;
    }
    const vehicleOtherAlertsCount = Object.keys(vehicleOtherAlerts).length;
    if (vehicleOtherAlertsCount) {
      vehiclesWithOtherAlerts.push(id);
      otherAlertsCount += vehicleOtherAlertsCount;
    }

    vehicles[id].warnings = vehicleWarningsCount;
    vehicles[id].warningsData = vehicleWarnings;
    vehicles[id].otherAlerts = vehicleOtherAlertsCount;
    vehicles[id].otherAlertsData = vehicleOtherAlerts;

    if (data.lowestPSI) {
      data.lowestPSI = Math.round(data.lowestPSI);
      Object.keys(data.tirePressures).forEach((key) => {
        data.tirePressures[key] = Math.round(data.tirePressures[key]);
      });
    }
  });

  const customer = {
    id: _id,
    steerCustomerId,
    name,
    isBusinessAccount,
    vehicles: Object.keys(vehicles),
    vehiclesWithConnectedDevices,
    vehiclesWithIgnitionOn: vehiclesWithIgnitionOn.length,
    vehiclesWithCheckEngineLight,
    vehiclesWithWarnings,
    vehiclesWithDTCCodes,
    vehiclesWithOtherAlerts,
    vehiclesData: vehicles,
    vehiclesInactive:
      Object.entries(vehicles).length - vehiclesWithConnectedDevices.length,
    warningsCount,
    otherAlertsCount,
    isVehiclesListLoading: false,
    hasVehicleListError: false,
    notes,
    email,
    phoneNumber,
    additionalPhoneNumbers,
    customerConsented,
    measurementSystem,
    shopId,
  };
  if (mojioCustomerId) customer.email = mojioCustomerId;

  return customer;
}
